// ** React Imports
import React, { useEffect, Fragment, useState } from 'react'
import { useParams } from 'react-router-dom'

// ** MUI Imports
import { useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Fade from '@mui/material/Fade'
import Card from '@mui/material/Card'
import Collapse from '@mui/material/Collapse'
import Backdrop from '@mui/material/Backdrop'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import CircularProgress from '@mui/material/CircularProgress'
import Paper from '@mui/material/Paper'
import TablePagination from '@mui/material/TablePagination'
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import Autocomplete from '@mui/material/Autocomplete'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import TextField from '@mui/material/TextField'

// ** Icon Imports
import Refresh from 'mdi-material-ui/Refresh'
import ChevronUp from 'mdi-material-ui/ChevronUp'
import ChevronDown from 'mdi-material-ui/ChevronDown'

// ** Third Party Imports
import moment from 'moment'

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'
import { customersSelector, getNotifications } from 'store/customers'

// Styled Card component
const StyledCard = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  border: `solid 1px ${theme.palette.grey[300]}`
}))

// Styled Divider component
const StyledDivider = styled(Divider)(({ theme }) => ({
  borderBottomWidth: 1,
  color: theme.palette.grey[300],
  marginTop: '-0.5rem',
  width: '120%',
  marginLeft: '-10%'
}))

const Row = props => {
  // ** Props
  const { row } = props

  return (
    <Fragment>
      <TableRow>
        <TableCell component='th' scope='row'>
          {moment(row.publishTime).format('MMM D, YYYY h:mm A [GMT]Z')}
        </TableCell>
        <TableCell align='right'>{row.customerDomain}</TableCell>
        <TableCell align='right'>
          {row.eventType
            .toLowerCase()
            .replace(/_/g, ' ')
            .replace(/\b\w/g, char => char.toUpperCase()) || '----'}
        </TableCell>
        <TableCell align='right'>{row.subscription?.skuName || '------'}</TableCell>
        <TableCell align='right'>
          {row.subscription?.plan?.planName === 'ANNUAL'
            ? row.subscription?.seats?.numberOfSeats
            : row.subscription?.seats?.licensedNumberOfSeats}
        </TableCell>
        <TableCell align='right'>{row.subscriptionId || '----'}</TableCell>
      </TableRow>
    </Fragment>
  )
}

const OrderHistory = () => {
  // ** States
  const [reload, setReload] = useState(false)
  const [collapsed, setCollapsed] = useState(false)
  const [visibility, setVisibility] = useState(true)

  // ** Hooks
  const dispatch = useDispatch()
  const { id } = useParams()
  const small = useMediaQuery(theme => theme.breakpoints.down('md'))

  // ** State
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [from, setFrom] = useState(moment().startOf('year').startOf('month'))
  const [to, setTo] = useState(moment())
  const [skuIds, setSkuIds] = useState([])
  const [eventTypes, setEventTypes] = useState([])
  const [refetch, setRefetch] = useState(0)

  // ** Selectors
  const { notificationsData, notificationsFilters, pagination, success, error, loading } =
    useSelector(customersSelector)

  // ** Functions
  const handleBackDrop = () => {
    setReload(true)
    setTimeout(() => {
      setReload(false)
    }, 2000)
  }

  // ** Fetch Notifications Data
  useEffect(() => {
    dispatch(
      getNotifications({
        customer_id: id,
        from: from.format('YYYY-MM-DD'),
        to: to.format('YYYY-MM-DD'),
        page,
        limit,
        eventTypes,
        skuIds: skuIds?.map(item => item.skuId)?.join(',')
      })
    )
  }, [from, to, page, limit, eventTypes, refetch, skuIds])

  // ** Functions
  const handleChangeRowsPerPage = event => {
    setLimit(event.target.value)
  }
  const handleChangePage = (event, newPage) => {
    // Increment Page if next button Clicked and there is nextPage (returned from the api)
    setPage(++newPage)
  }

  return (
    <Fade in={visibility} timeout={300}>
      <StyledCard sx={{ position: 'relative' }}>
        <CardHeader
          title='Order History'
          action={
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                size='small'
                aria-label='collapse'
                sx={{ mr: 2, color: 'text.secondary' }}
                onClick={() => setCollapsed(!collapsed)}
              >
                {!collapsed ? <ChevronDown fontSize='small' /> : <ChevronUp fontSize='small' />}
              </IconButton>
              <IconButton
                size='small'
                aria-label='reload'
                onClick={() => setRefetch(state => ++state)}
                sx={{ mr: 2, color: 'text.secondary' }}
              >
                <Refresh fontSize='small' />
              </IconButton>
            </Box>
          }
        />
        <Collapse in={collapsed}>
          <CardContent sx={{ ml: '-1rem' }}>
            <StyledDivider />
            <Box p={5} display='flex' flexDirection={small ? 'column' : 'row'} alignItems='center' gap={5}>
              <Autocomplete
                onChange={(e, val) => setEventTypes(val ?? '')}
                multiple
                value={eventTypes || []}
                options={notificationsFilters?.event_types || []}
                getOptionLabel={option =>
                  option
                    .toLowerCase()
                    .replace(/_/g, ' ')
                    .replace(/\b\w/g, char => char.toUpperCase())
                }
                size='small'
                sx={{ width: '100%', '.MuiAutocomplete-tag': { background: '#00000014' } }}
                renderInput={params => <TextField {...params} label='Search by event' />}
              />
              <Autocomplete
                onChange={(e, val) => setSkuIds(val ?? [])}
                value={skuIds || []}
                multiple
                options={notificationsFilters?.subscriptions || []}
                getOptionLabel={option => option.subscription_name}
                renderOption={(props, option) => (
                  <li {...props} key={option.skuId}>
                    {option.subscription_name}
                  </li>
                )}
                size='small'
                sx={{ width: '100%', '.MuiAutocomplete-tag': { background: '#00000014' } }}
                renderInput={params => <TextField {...params} label='Search by subscription' />}
              />
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <Box width={small ? '100%' : '50%'}>
                  <DesktopDatePicker
                    label='From'
                    inputFormat='MM/DD/YYYY'
                    value={from}
                    onChange={newValue => setFrom(newValue)}
                    sx={{ width: '100%' }}
                    slotProps={{ textField: { size: 'small' } }}
                    renderInput={params => <TextField {...params} />}
                  />
                </Box>
                <Box width={small ? '100%' : '50%'}>
                  <DesktopDatePicker
                    label='To'
                    inputFormat='MM/DD/YYYY'
                    value={to}
                    onChange={newValue => setTo(newValue)}
                    sx={{ width: '100%' }}
                    slotProps={{ textField: { size: 'small' } }}
                    renderInput={params => <TextField {...params} />}
                  />
                </Box>
              </LocalizationProvider>
            </Box>
            <Typography variant='body2'>{/* <OrderHistoryTable /> */}</Typography>
            <Box sx={{ position: 'relative' }}>
              <TableContainer component={Paper}>
                <Table aria-label='collapsible table'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Timestamp</TableCell>
                      <TableCell align='right'>User</TableCell>
                      <TableCell align='right'>Action</TableCell>
                      <TableCell align='right'>Product</TableCell>
                      <TableCell align='right'>Quantity</TableCell>
                      <TableCell align='right'>Order ID</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {notificationsData?.map(row => (
                      <Row key={row.id} row={row} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 20, 50]}
                component='div'
                count={pagination.total}
                rowsPerPage={pagination.limit}
                page={pagination.currentPage - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              <Backdrop
                open={loading == 'GET_CUSTOMER_NOTIFICATIONS'}
                sx={{
                  position: 'absolute',
                  color: theme => theme.palette.common.blue,
                  zIndex: theme => theme.zIndex.mobileStepper - 1,
                  '&.MuiBackdrop-root': {
                    backgroundColor: 'common.white'
                  }
                }}
              >
                <CircularProgress color='inherit' />
              </Backdrop>
            </Box>
          </CardContent>
        </Collapse>
      </StyledCard>
    </Fade>
  )
}

export default OrderHistory
