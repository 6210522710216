// ** React Imports
import React, { forwardRef, Fragment, useEffect, useState } from 'react'

// ** MUI Imports
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Slide from '@mui/material/Slide'
import Tab from '@mui/material/Tab'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import FormHelperText from '@mui/material/FormHelperText'
import CircularProgress from '@mui/material/CircularProgress'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

// ** MUI Lab
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import TabContext from '@mui/lab/TabContext'

// ** Icon Imports
import DeleteIcon from '@mui/icons-material/DeleteOutline'

// ** Third Party imports
import moment from 'moment'

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'
import {
  customersSelector,
  editSubDiscount,
  editSubPricing,
  updateNonGoogleSkuSubscription,
  editCustomerSubscription,
  cleanMessages
} from 'store/customers'
import { fetchPricingbooksData, pricingBooksSelector } from 'store/metaData/pricingbook'
import { useAuth } from 'hooks/useAuth'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

// Helper to check if two date ranges overlap
const isDateRangeOverlapping = (start1, end1, start2, end2) => {
  return moment(start1).isSameOrBefore(moment(end2)) && moment(start2).isSameOrBefore(moment(end1))
}

const formatDateString = dateStr => new Date(dateStr).toISOString() // Ensure consistent date format

const SubscriptionEdit = props => {
  // ** Hooks
  const dispatch = useDispatch()
  const { user } = useAuth()
  // ** Props
  const { subscription, customerId } = props

  // ** Selectors
  const { customerInformation, success, error, loading } = useSelector(customersSelector)
  const { pricingBooksData } = useSelector(pricingBooksSelector)

  // ** State
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState('1')
  const [endDate, setEndDate] = useState(new Date())
  const [startDate, setStartDate] = useState(new Date())
  const [initialEndDate, setInitialEndDate] = useState(new Date())
  const [initialStartDate, setInitialStartDate] = useState(new Date())
  const [discount, setDiscount] = useState(0) // type vendor
  const [discountMargin, setDiscountMargin] = useState(0) // type margin
  const [deletedDiscounts, setDeletedDiscounts] = useState([]) // type margin
  const [margin, setMargin] = useState(0)
  const [seats, setSeats] = useState(0)
  const [subscriptionPricingBooks, setSubscriptionPricingBooks] = useState({
    startDate: new Date(),
    endDate: new Date(),
    pricingBookId: null,
    subscriptionId: subscription.subscriptionId
  })
  const [initialSubscriptionPricingBooks, setInitialSubscriptionPricingBooks] = useState({
    startDate: new Date(),
    endDate: new Date(),
    pricingBookId: null,
    subscriptionId: subscription.subscriptionId
  })

  const [subDiscounts, setSubDiscount] = useState([])
  const [subDiscountsCopy, setSubDiscountCopy] = useState([])
  const [validDatesInputs, setValidDatesInputs] = useState([])
  const [canSubmit, setCanSubmit] = useState(false)

  // ** Constants
  const subVendorDiscount = customerInformation?.discounts.find(
    disc => disc.subscriptionId === subscription.subscriptionId && disc.type === 'vendor'
  )
  const subMarginDiscount = customerInformation?.discounts.find(
    disc => disc.subscriptionId === subscription.subscriptionId && disc.type === 'margin'
  )

  const subMargin = customerInformation?.googleIds
    ?.flatMap(googleId => googleId.subscriptions)
    ?.find(sub => sub.subscriptionId === subscription.subscriptionId)

  const subPricing = customerInformation?.subscriptionPricingBooks?.find(
    disc => disc.subscriptionId == subscription.subscriptionId
  )

  const initialDiscounts = customerInformation?.discounts?.filter(
    item => item.subscriptionId === subscription.subscriptionId
  )

  // ** Set dicounts array
  useEffect(() => {
    const defaultValues = [
      [
        {
          customerId: customerInformation.id,
          skuId: subscription.skuId,
          subscriptionId: subscription.subscriptionId,
          value: 0,
          type: 'vendor',
          startDate: new Date().setHours(0, 0, 0, 0),
          endDate: new Date().setHours(0, 0, 0, 0)
        },
        {
          customerId: customerInformation.id,
          skuId: subscription.skuId,
          subscriptionId: subscription.subscriptionId,
          value: 0,
          type: 'margin',
          startDate: new Date().setHours(0, 0, 0, 0),
          endDate: new Date().setHours(0, 0, 0, 0)
        }
      ]
    ]
    setDeletedDiscounts([])
    if (subscription?.id && customerInformation?.discounts?.length) {
      const filteredDiscounts = customerInformation.discounts?.filter(
        item => item.subscriptionId === subscription.subscriptionId
      )
      // ?.reduce((unique, current) => {
      //   const duplicate = unique.find(
      //     item =>
      //       item.startDate === current.startDate &&
      //       item.endDate === current.endDate &&
      //       item.type === current.type &&
      //       item.subscriptionId === current.subscriptionId
      //   )

      //   if (duplicate) {
      //     // If a duplicate is found, keep the one with the latest createdAt
      //     return new Date(duplicate.createdAt) > new Date(current.createdAt)
      //       ? unique
      //       : unique.map(item => (item === duplicate ? current : item))
      //   } else {
      //     // If no duplicate, add to the unique list
      //     return [...unique, current]
      //   }
      // }, [])

      console.log('----before---', filteredDiscounts)
      const formattedDiscounts = filteredDiscounts
        ?.map(item => ({
          ...item,
          startDate: new Date(moment(item.startDate).utc(false).format('YYYY/MM/DD')),
          endDate: new Date(moment(item.endDate).utc(false).format('YYYY/MM/DD'))
        }))
        .reduce((acc, obj) => {
          // Use startDate and type as composite keys
          const startDateKey = moment(obj.startDate).format('YYYY/MM/DD HH:mm')
          const key = `${startDateKey}_${obj.type}`

          // Find or create a group for the startDate
          if (!acc[startDateKey]) {
            acc[startDateKey] = []
          }

          // Add the object to the group only if it maintains the vendor-margin pair logic
          const group = acc[startDateKey]
          const hasVendor = group.some(item => item.type === 'vendor')
          const hasMargin = group.some(item => item.type === 'margin')

          if ((obj.type === 'vendor' && !hasVendor) || (obj.type === 'margin' && !hasMargin)) {
            group.push(obj)
          } else if (group.length === 2) {
            // Create a new group if the current one is full
            acc[startDateKey] = [obj]
          }

          return acc
        }, {})

      const completeDiscounts = Object.values(formattedDiscounts).map(group => {
        if (group.length < 2) {
          const existingDiscount = group[0]
          const missingType = existingDiscount.type.toLowerCase() === 'vendor' ? 'margin' : 'vendor'

          const { id, createdAt, updatedAt, ...rest } = existingDiscount
          group.push({
            ...rest,
            type: missingType,
            value: 0
          })
        }

        return group.sort((a, b) => (a.type.toLowerCase() === 'vendor' ? -1 : 1))
      })

      if (completeDiscounts.length > 0) {
        setSubDiscount(completeDiscounts)
        setSubDiscountCopy(completeDiscounts)
      } else {
        setSubDiscount(defaultValues)
        setSubDiscountCopy(defaultValues)
      }
    } else {
      setSubDiscount(defaultValues)
      setSubDiscountCopy(defaultValues)
    }
  }, [subscription, customerInformation])

  // ** Clean up
  useEffect(() => {
    if (customerInformation?.id) {
      setSeats(
        subscription?.planName === 'ANNUAL'
          ? subscription?.numberOfSeats
          : subscription?.planName === 'FLEXIBLE'
          ? subscription?.licensedNumberOfSeats
          : 0
      )
      if (subVendorDiscount == undefined) {
        setDiscount(0)
        setStartDate(new Date())
        setEndDate(new Date())
      } else {
        setDiscount(subVendorDiscount?.value)
        setInitialStartDate(new Date(moment(subVendorDiscount.startDate).utc(false).format('YYYY/MM/DD')))
        setInitialEndDate(new Date(moment(subVendorDiscount.endDate).utc(false).format('YYYY/MM/DD')))
        setStartDate(new Date(moment(subVendorDiscount.startDate).utc(false).format('YYYY/MM/DD')))
        setEndDate(new Date(moment(subVendorDiscount.endDate).utc(false).format('YYYY/MM/DD')))
        setDiscountMargin(subMarginDiscount?.value || 0)
      }
      if (subMargin?.margin) {
        setMargin(subMargin?.margin || 0)
      }
      if (subPricing?.id) {
        setInitialSubscriptionPricingBooks({
          endDate: new Date(moment(subPricing.endDate).utc(false).format('YYYY/MM/DD')),
          startDate: new Date(moment(subPricing.startDate).utc(false).format('YYYY/MM/DD')),
          pricingBookId: subPricing.pricingBookId,
          subscriptionId: subscription.subscriptionId
        })
        setSubscriptionPricingBooks({
          endDate: new Date(moment(subPricing.endDate).utc(false).format('YYYY/MM/DD')),
          startDate: new Date(moment(subPricing.startDate).utc(false).format('YYYY/MM/DD')),
          pricingBookId: subPricing.pricingBookId,
          subscriptionId: subscription.subscriptionId
        })
      } else {
        setSubscriptionPricingBooks({
          startDate: new Date(),
          endDate: new Date(),
          pricingBookId: null,
          subscriptionId: subscription.subscriptionId
        })
      }
    }
  }, [subscription, customerInformation])

  useEffect(() => {
    if (pricingBooksData?.length < 1) dispatch(fetchPricingbooksData({ status: 'active' }))
  }, [])

  useEffect(() => {
    if (success == 'UPDATE_SUBSCRIPTION' || error == 'UPDATE_SUBSCRIPTION') {
      setOpen(false)
    }
  }, [success, error])

  // ** Fucntions
  const handleClickOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    setValue('1')
  }

  const onDeleteDiscount = targetStartDate => {
    const targetDate = new Date(targetStartDate).toISOString() // Normalize targetStartDate to ISO format

    // Separate the groups to be deleted and the remaining groups
    const [toDelete, remainingDiscounts] = subDiscounts.reduce(
      ([deleting, remaining], group) => {
        if (group.every(item => new Date(item.startDate).toISOString() === targetDate)) {
          return [[...deleting, ...group], remaining]
        }
        return [deleting, [...remaining, group]]
      },
      [[], []]
    )

    // Update the deletedItems state
    setDeletedDiscounts(prev => [...prev, ...toDelete])
    setSubDiscount(remainingDiscounts)
  }

  const onChangeDiscountMargin = amount => {
    setDiscountMargin(parseFloat(amount))
  }
  const onChangeMargin = amount => {
    setMargin(amount)
  }
  const onChangeSeats = value => {
    setSeats(value)
  }

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const onChangePricingBook = event => {
    setSubscriptionPricingBooks(state => ({ ...state, pricingBookId: event.target.value }))
  }

  // const onChangeStartDateHandler = date => {
  //   setStartDate(date)
  //   setEndDate(new Date(date).setDate(new Date(date).getDate() + 30))
  // }

  // Validate date ranges for all items and store the results
  const validateDateRanges = () => {
    const validInputs = subDiscounts.map((item, idx) => {
      const { startDate: start1, endDate: end1 } = item[0]
      const isValid = !subDiscounts.some((other, otherIdx) => {
        if (idx === otherIdx) return false // Skip the same item
        const { startDate: start2, endDate: end2 } = other[0]
        return isDateRangeOverlapping(start1, end1, start2, end2)
      })
      return isValid
    })
    setValidDatesInputs(validInputs)
  }

  const checkCanSubmit = () => {
    const allValid = validDatesInputs?.every(Boolean)
    const noZeroValues = subDiscounts?.every(item => !(item[0]?.value === 0 && item[1]?.value === 0))
    const flattenedArray = subDiscounts?.flat()?.map(item => ({
      ...(!!item?.id ? { id: item?.id } : {}),
      type: item.type,
      startDate: moment(item.startDate).format('YYYY-MM-DD'),
      endDate: moment(item.endDate).format('YYYY-MM-DD'),
      skuId: subscription?.skuId,
      subscriptionId: subscription.subscriptionId,
      value: parseFloat(item.value)
    }))

    const filteredArray = flattenedArray.filter(item => {
      // If item doesn't have an 'id', keep it
      if (!item.id) return true

      // Find the matching object from the firstArray using the same 'id'
      const matchingItem = initialDiscounts.find(firstItem => firstItem.id === item.id)

      // If there's no matching object, keep the item (new entry)
      if (!matchingItem) return true

      // Compare 'startDate', 'endDate', and 'value'
      const hasSameValues =
        formatDateString(matchingItem.startDate) === formatDateString(item.startDate) &&
        formatDateString(matchingItem.endDate) === formatDateString(item.endDate) &&
        matchingItem.value === item.value

      // If all values are the same, filter it out (remove); otherwise, keep it
      return !hasSameValues
    })
    const valid = filteredArray?.length ? allValid && noZeroValues : !!deletedDiscounts?.length
    setCanSubmit(valid)
  }

  const validateDiscounts = () => {
    return subDiscounts.some(group => group.some(item => item.type === 'margin' && item.value > subscription?.margin))
  }

  // const checkChanges = () => {
  //   // Compare each inner array
  //   const hasModifiedObjects = subDiscounts.some((innerArray, index) => {
  //     if (!subDiscountsCopy[index]) return true; // New inner array added
  //     return innerArray.some((obj) => {
  //       if (obj.id) {
  //         // Compare objects with `id`
  //         const original = subDiscountsCopy[index].find((copy) => copy.id === obj.id);
  //         if (!original) return true; // Object removed or changed completely
  //         return (
  //           obj.value !== original.value ||
  //           obj.startDate !== original.startDate ||
  //           obj.endDate !== original.endDate
  //         );
  //       }
  //       return false;
  //     });
  //   });

  //   // Check new objects in new inner arrays
  //   const hasNewObjectsWithValue = subDiscounts.some((innerArray) =>
  //     innerArray.some((obj) => !obj.id && obj.value !== 0)
  //   );

  //   return hasModifiedObjects || hasNewObjectsWithValue;
  // };

  useEffect(() => {
    checkCanSubmit()
  }, [validDatesInputs])

  useEffect(() => {
    validateDateRanges()
  }, [subDiscounts, subDiscounts])

  // Handle changes for the first or second discount value
  const handleDiscountChange = (index, valueIndex, value) => {
    const updatedDiscounts = [...subDiscounts]
    updatedDiscounts[index][valueIndex].value = value
    setSubDiscount(updatedDiscounts)
    // Update state here if using a state hook to store subDiscounts
    console.log('Updated Discounts: ', updatedDiscounts)
  }

  // Handle changes to the start date and update the end date accordingly
  const onChangeStartDateHandler = (index, valueIndex, date) => {
    const updatedDiscounts = [...subDiscounts]
    updatedDiscounts[index][1].startDate = date
    updatedDiscounts[index][0].startDate = date

    // Set the end date to be 30 days after the start date
    const newEndDate = new Date(date)
    newEndDate.setFullYear(newEndDate.getFullYear() + 1)
    updatedDiscounts[index][0].endDate = newEndDate
    updatedDiscounts[index][1].endDate = newEndDate
    setSubDiscount(updatedDiscounts)

    // Update state if needed here
    console.log('Updated Start and End Date: ', updatedDiscounts)
  }

  // Handle changes to the end date only
  const onChangeEndDateHandler = (index, valueIndex, date) => {
    const updatedDiscounts = [...subDiscounts]
    updatedDiscounts[index][1].endDate = date
    updatedDiscounts[index][0].endDate = date
    setSubDiscount(updatedDiscounts)
    // Update state if needed here
    console.log('Updated End Date: ', updatedDiscounts)
  }

  const isButtonDisabled = subDiscounts?.some(group => group?.every(obj => obj.value === 0 && !obj.id))

  const addNewObjects = () => {
    const today = new Date()
    today.setHours(0, 0, 0, 0) // Set hours, minutes, seconds, and milliseconds to 0

    const newObjects = [
      {
        customerId: customerInformation.id,
        skuId: subscription.skuId,
        subscriptionId: subscription.subscriptionId,
        value: 0,
        type: 'vendor',
        startDate: today,
        endDate: today
      },
      {
        customerId: customerInformation.id,
        skuId: subscription.skuId,
        subscriptionId: subscription.subscriptionId,
        value: 0,
        type: 'margin',
        startDate: today,
        endDate: today
      }
    ]

    setSubDiscount(prevData => [...prevData, newObjects])
  }

  const submitDiscount = () => {
    console.log('---submit---')
    if (value === '2') {
      if (subPricing?.subscriptionId) {
        const log = {
          customer_id: customerId,
          date: new Date(),
          user: user.email,
          model: 'subscription',
          instance_name: subscription.subscriptionId,
          old_values: {
            pricingBook: customerInformation.subscriptionPricingBooks?.find(pricing => pricing.id == subPricing?.id)
          },
          new_values: {
            pricingBook: {
              id: subPricing?.id,
              startDate: moment(subscriptionPricingBooks.startDate).format('YYYY-MM-DD'),
              endDate: moment(subscriptionPricingBooks.endDate).format('YYYY-MM-DD'),
              subscriptionId: subscriptionPricingBooks.subscriptionId,
              pricingBookId: subscriptionPricingBooks.pricingBookId,
              customerId
            }
          }
        }
        dispatch(
          editSubPricing(
            {
              id: subPricing?.id,
              startDate: moment(subscriptionPricingBooks.startDate).format('YYYY-MM-DD'),
              endDate: moment(subscriptionPricingBooks.endDate).format('YYYY-MM-DD'),
              subscriptionId: subscriptionPricingBooks.subscriptionId,
              pricingBookId: subscriptionPricingBooks.pricingBookId,
              customerId
            },
            log
          )
        )
      } else if (subscriptionPricingBooks?.pricingBookId) {
        dispatch(
          editSubPricing({
            startDate: moment(subscriptionPricingBooks.startDate).format('YYYY-MM-DD'),
            endDate: moment(subscriptionPricingBooks.endDate).format('YYYY-MM-DD'),
            subscriptionId: subscriptionPricingBooks.subscriptionId,
            pricingBookId: subscriptionPricingBooks.pricingBookId,
            customerId
          })
        )
      }
    } else if (value === '3') {
      const dailyChangesData = {
        subscription_id: subscription?.subscriptionId,
        day: moment().format('YYYY-MM-DD')
      }
      const data = {
        ...subscription,
        ...(subscription?.planName?.includes('ANNUAL')
          ? { numberOfSeats: parseInt(seats) }
          : subscription?.planName?.includes('FLEXIBLE')
          ? { licensedNumberOfSeats: parseInt(seats) }
          : {})
      }
      dispatch(updateNonGoogleSkuSubscription(data, dailyChangesData))
    } else if (value === '4') {
      const updatedSubscription = { ...subscription, margin: parseFloat(margin) }
      dispatch(editCustomerSubscription(customerInformation, updatedSubscription))
    } else {
      const flattenedArray = subDiscounts?.flat()?.map(item => ({
        ...(!!item?.id ? { id: item?.id } : {}),
        type: item.type,
        startDate: moment(item.startDate).format('YYYY-MM-DD'),
        endDate: moment(item.endDate).format('YYYY-MM-DD'),
        skuId: subscription?.skuId,
        subscriptionId: subscription.subscriptionId,
        value: parseFloat(item.value)
      }))

      // const filteredArray = flattenedArray.filter(item => {
      //   // If item doesn't have an 'id', keep it
      //   if (!item.id) return true

      //   // Find the matching object from the firstArray using the same 'id'
      //   const matchingItem = initialDiscounts.find(firstItem => firstItem.id === item.id)

      //   // If there's no matching object, keep the item (new entry)
      //   if (!matchingItem) return true

      //   // Compare 'startDate', 'endDate', and 'value'
      //   const hasSameValues =
      //     formatDateString(matchingItem.startDate) === formatDateString(item.startDate) &&
      //     formatDateString(matchingItem.endDate) === formatDateString(item.endDate) &&
      //     matchingItem.value === item.value

      //   // If all values are the same, filter it out (remove); otherwise, keep it
      //   return !hasSameValues
      // })

      if (flattenedArray?.length || deletedDiscounts?.length > 0) {
        const log = {
          customer_id: customerId,
          date: new Date(),
          user: user.email,
          model: 'subscription',
          instance_name: subscription.skuName,
          old_values: {
            discounts: customerInformation.discounts
              ?.filter(sub => flattenedArray.some(item => item.id == sub.id))
              ?.map(item => ({
                ...item,
                startDate: moment(item.startDate).format('YYYY-MM-DD'),
                endDate: moment(item.endDate).format('YYYY-MM-DD')
              }))
          },
          new_values: {
            discounts: flattenedArray,
            ...(deletedDiscounts?.length > 0 ? { deletedDiscounts: deletedDiscounts } : {})
          }
        }
        const prices = [
          {
            skuId: subscription.skuId,
            flexiblePriceCad: subscription.product?.flexiblePriceCad || null,
            flexiblePriceEur: subscription.product?.flexiblePriceEur || null,
            flexiblePriceUsd: subscription.product?.flexiblePriceUsd || null,
            priceCad: subscription.product?.priceCad || null,
            priceEur: subscription.product?.priceEur || null,
            priceUsd: subscription.product?.priceUsd || null
          }
        ]

        dispatch(
          editSubDiscount({
            customerId,
            discounts: flattenedArray,
            deletedDiscounts: deletedDiscounts.filter(item => !!item.id),
            log,
            startBillingDate: customerInformation.startBillingDate,
            billingType: customerInformation.billingType,
            billingCycle: customerInformation.billingCycle,
            type: customerInformation.billingType === 'inadvance' ? 'advance' : 'rear',
            prices
          })
        )
      }
    }
  }

  return (
    <Fragment>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Button
          variant='text'
          onClick={handleClickOpen}
          sx={{
            ml: 'auto',
            boxShadow: 0,
            fontWeight: 600,
            '&:hover': {
              backgroundColor: 'common.white',
              boxShadow: 0
            }
          }}
        >
          Edit
        </Button>
        <Dialog
          open={open}
          keepMounted
          onClose={handleClose}
          TransitionComponent={Transition}
          aria-labelledby='alert-dialog-slide-title'
          aria-describedby='alert-dialog-slide-description'
          sx={{
            '& .MuiDialog-container': {
              '& .MuiPaper-root': {
                width: '100%',
                minWidth: '60%' // Set your width here
              }
            }
          }}
        >
          <DialogTitle id='alert-dialog-slide-title' sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ fontWeight: 600, fontSize: '1.5rem' }}>Edit subscription</Typography>
          </DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item xs={12}>
                <TabContext value={value}>
                  <TabList
                    sx={{ mt: '0.725rem', '& .MuiButtonBase-root': { textTransform: 'capitalize' } }}
                    onChange={handleChange}
                    aria-label='full width tabs example'
                  >
                    <Tab value='1' label='Discount' />
                    <Tab value='2' label='Pricing book' />
                    {subscription?.is_not_google_product ? <Tab value='3' label='Seats' /> : null}
                    <Tab value='4' label='Margin' />
                  </TabList>
                  <TabPanel value='1'>
                    {error === 'ERROR_CHECK_RECALCULATION' ? (
                      <Grid item xs={12}>
                        <Typography>
                          There are already generated invoices within the selected date range where discounts have been
                          applied. To apply new discounts, please delete all existing invoices for that period.
                        </Typography>
                      </Grid>
                    ) : (
                      <>
                        <Grid item xs={12} my={3}>
                          <Button variant='contained' size='small' onClick={addNewObjects} disabled={isButtonDisabled}>
                            add discount
                          </Button>
                          <Divider sx={{ borderBottom: theme => `2px solid ${theme.palette.grey[300]}`, my: 3 }} />
                        </Grid>
                        {subDiscounts?.map((item, idx) => (
                          <Grid key={idx} item spacing={4}>
                            <Grid container spacing={4}>
                              <Grid item xs={12} sm={3} sx={{ my: 2 }}>
                                <TextField
                                  value={item[0]?.value}
                                  label='Discount'
                                  size='small'
                                  type='number'
                                  onChange={e => handleDiscountChange(idx, 0, e.target.value)}
                                  error={item[0]?.value < 0 || item[0]?.value > 100}
                                  sx={{
                                    width: '100%'
                                  }}
                                  InputProps={{
                                    endAdornment: <text>%</text>
                                  }}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                />
                                {(item[0].value < 0 || item[0].value > 100) && (
                                  <FormHelperText sx={{ color: 'error.main' }} id='validation-basic-last-name'>
                                    Discount should be between 0 and 100
                                  </FormHelperText>
                                )}
                              </Grid>
                              <Grid item xs={12} sm={3.5} sx={{ my: 2 }}>
                                <DesktopDatePicker
                                  label='Start date'
                                  inputFormat='MM/DD/YYYY'
                                  value={item[0]?.startDate}
                                  //maxDate={new Date(item[0].endDate)}
                                  onChange={date => onChangeStartDateHandler(idx, 0, date)}
                                  sx={{ width: '100%' }}
                                  slotProps={{ textField: { size: 'small' } }}
                                  renderInput={params => <TextField {...params} />}
                                />
                                {!validDatesInputs[idx] ? (
                                  <FormHelperText sx={{ color: 'error.main' }} id='validation-basic-last-name'>
                                    The selected date range overlaps with an existing one. Please choose a different
                                    range.
                                  </FormHelperText>
                                ) : null}
                              </Grid>
                              <Grid item xs={12} sm={3.5} sx={{ my: 2 }}>
                                <DesktopDatePicker
                                  label='End date'
                                  value={item[0]?.endDate}
                                  inputFormat='MM/DD/YYYY'
                                  minDate={new Date(item[0].startDate)}
                                  onChange={date => onChangeEndDateHandler(idx, 0, date)}
                                  sx={{ width: '100%' }}
                                  slotProps={{ textField: { size: 'small' } }}
                                  renderInput={params => <TextField {...params} />}
                                />
                              </Grid>
                              <Grid item xs={12} sm={2} sx={{ my: 2 }}>
                                <TextField value='Vendor' label='Type' size='small' disabled />
                              </Grid>
                            </Grid>
                            <Grid container spacing={4} mt={0}>
                              <Grid item xs={12} sm={3} sx={{ my: 2 }}>
                                <TextField
                                  value={item[1]?.value}
                                  label='Discount'
                                  size='small'
                                  type='number'
                                  onChange={e => handleDiscountChange(idx, 1, e.target.value)}
                                  error={item[1]?.value < 0 || item[1]?.value > subscription.margin}
                                  sx={{
                                    width: '100%'
                                  }}
                                  InputProps={{
                                    endAdornment: <text>%</text>
                                  }}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                />
                                {(item[1]?.value < 0 || item[1]?.value > subscription.margin) && (
                                  <FormHelperText sx={{ color: 'error.main' }} id='validation-basic-last-name'>
                                    The discount must be less than the margin discount ({subscription?.margin || 0}%).
                                    Please adjust the value.
                                  </FormHelperText>
                                )}
                              </Grid>
                              <Grid item xs={12} sm={3.5} sx={{ my: 2 }}>
                                <DesktopDatePicker
                                  label='Start date'
                                  inputFormat='MM/DD/YYYY'
                                  value={item[1]?.startDate}
                                  onChange={date => onChangeStartDateHandler(idx, 1, date)}
                                  sx={{ width: '100%' }}
                                  slotProps={{ textField: { size: 'small' } }}
                                  renderInput={params => <TextField {...params} />}
                                />
                              </Grid>
                              <Grid item xs={12} sm={3.5} sx={{ my: 2 }}>
                                <DesktopDatePicker
                                  label='End date'
                                  value={item[1]?.endDate}
                                  onChange={date => onChangeEndDateHandler(idx, 1, date)}
                                  inputFormat='MM/DD/YYYY'
                                  sx={{ width: '100%' }}
                                  slotProps={{ textField: { size: 'small' } }}
                                  renderInput={params => <TextField {...params} />}
                                />
                              </Grid>
                              <Grid item xs={12} sm={2} sx={{ my: 2 }}>
                                <TextField value='Margin' label='Type' size='small' disabled />
                              </Grid>
                            </Grid>
                            <Grid container spacing={4} mt={1}>
                              <Grid item xs={12} sm={3}>
                                <TextField
                                  value={
                                    Number.isInteger(parseFloat(item[0]?.value || 0) + parseFloat(item[1]?.value || 0))
                                      ? (parseFloat(item[0]?.value || 0) + parseFloat(item[1]?.value || 0)).toString()
                                      : (parseFloat(item[0]?.value || 0) + parseFloat(item[1]?.value || 0)).toFixed(2)
                                  }
                                  label='Total'
                                  size='small'
                                  disabled
                                  fullWidth
                                />
                              </Grid>
                              {subDiscounts?.length > 0 ? (
                                <Grid item xs={12} sm={9} sx={{ display: 'flex', justifyContent: 'end' }}>
                                  <Button
                                    variant='text'
                                    color='error'
                                    onClick={() => onDeleteDiscount(item[1]?.startDate)}
                                    startIcon={
                                      <DeleteIcon
                                        sx={{ fontSize: '18px' }}
                                        color={subDiscounts?.length < 2 ? 'disabled' : 'error'}
                                      />
                                    }
                                    disabled={subDiscounts?.length < 2}
                                    sx={{ '&:disabled': { color: 'gray !important' } }}
                                  >
                                    Delete
                                  </Button>
                                </Grid>
                              ) : null}
                            </Grid>
                            <Divider sx={{ borderBottom: theme => `2px solid ${theme.palette.grey[300]}`, my: 5 }} />
                          </Grid>
                        ))}
                      </>
                    )}
                  </TabPanel>
                  <TabPanel value='2'>
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={4} sx={{ my: 2 }}>
                        <FormControl fullWidth size='small'>
                          <InputLabel id='pricing-book-label'>Pricing book</InputLabel>
                          <Select
                            value={subscriptionPricingBooks.pricingBookId}
                            label='Pricing Book'
                            onChange={onChangePricingBook}
                            labelId='pricing-book-label'
                            MenuProps={{
                              anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left'
                              },
                              transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left'
                              },
                              getContentAnchorEl: null
                            }}
                          >
                            <MenuItem value={null} disabled>
                              <em>None</em>
                            </MenuItem>
                            {pricingBooksData?.map(pricing => (
                              <MenuItem key={pricing.id} value={pricing.id}>
                                {pricing.name} {pricing.is_default ? '(default)' : ''}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4} sx={{ my: 2 }}>
                        <DesktopDatePicker
                          label='Start date'
                          value={subscriptionPricingBooks.startDate}
                          inputFormat='MM/DD/YYYY'
                          onChange={newValue =>
                            setSubscriptionPricingBooks(state => ({
                              ...state,
                              startDate: newValue,
                              endDate: new Date(newValue).setDate(new Date(newValue).getDate() + 30)
                            }))
                          }
                          renderInput={params => <TextField {...params} />}
                          slotProps={{ textField: { size: 'small' } }}
                          sx={{ width: '100%' }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} sx={{ my: 2 }}>
                        <DesktopDatePicker
                          label='End date'
                          value={subscriptionPricingBooks.endDate}
                          inputFormat='MM/DD/YYYY'
                          minDate={new Date(subscriptionPricingBooks.startDate)}
                          onChange={newValue => setSubscriptionPricingBooks(state => ({ ...state, endDate: newValue }))}
                          renderInput={params => <TextField {...params} />}
                          slotProps={{ textField: { size: 'small' } }}
                          sx={{ width: '100%' }}
                        />
                      </Grid>
                    </Grid>
                  </TabPanel>
                  {subscription?.is_not_google_product ? (
                    <TabPanel value='3'>
                      <Grid container>
                        <Grid item xs={12} md={6} sx={{ my: 2 }}>
                          <TextField
                            value={seats}
                            label='Seats number'
                            size='small'
                            onChange={e => onChangeSeats(e.target.value)}
                            error={seats < 0 || seats > 50000}
                            sx={{
                              width: '100%'
                            }}
                          />
                          {(seats < 0 || seats > 50000) && (
                            <FormHelperText sx={{ color: 'error.main' }} id='validation-basic-last-name'>
                              Seats should be between 0 and 100
                            </FormHelperText>
                          )}
                        </Grid>
                      </Grid>
                    </TabPanel>
                  ) : null}
                  <TabPanel value='4'>
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={4} sx={{ my: 2 }}>
                        <TextField
                          value={margin}
                          label='Margin'
                          size='small'
                          onChange={e => onChangeMargin(e.target.value)}
                          error={margin < 0 || margin > 100}
                          sx={{
                            width: '100%'
                          }}
                          InputProps={{
                            endAdornment: <text>%</text>
                          }}
                        />
                        {(margin < 0 || margin > 100) && (
                          <FormHelperText sx={{ color: 'error.main' }} id='validation-basic-last-name'>
                            Margin should be between 0 and 100
                          </FormHelperText>
                        )}
                      </Grid>
                    </Grid>
                  </TabPanel>
                </TabContext>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className='dialog-actions-dense'>
            {error === 'ERROR_CHECK_RECALCULATION' ? (
              <Button
                variant='contained'
                sx={{
                  fontWeight: 600,
                  '&:hover': {
                    backgroundColor: 'common.blue'
                  },
                  '&:disabled': {
                    backgroundColor: theme => theme.palette.grey[700],
                    color: 'common.white'
                  }
                }}
                onClick={() => dispatch(cleanMessages())}
              >
                OK
              </Button>
            ) : (
              <>
                <Button
                  sx={{
                    color: 'text.secondary',
                    fontWeight: 600,
                    '&:hover': {
                      backgroundColor: theme => `${theme.palette.common.white} !important`,
                      color: 'text.secondary'
                    }
                  }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  variant='contained'
                  sx={{
                    fontWeight: 600,
                    '&:hover': {
                      backgroundColor: 'common.blue'
                    },
                    '&:disabled': {
                      backgroundColor: theme => theme.palette.grey[700],
                      color: 'common.white'
                    }
                  }}
                  startIcon={
                    (loading == 'UPDATE_SUBSCRIPTION' ||
                      loading === 'UPDATE_NON_GOOGLE_SUBSCRIPTION' ||
                      loading === 'UPDATE_CUSTOMER_SUBSCRIPTION') && (
                      <CircularProgress sx={{ color: 'common.white' }} size='1rem' />
                    )
                  }
                  onClick={submitDiscount}
                  disabled={
                    (value === '1' && (!canSubmit || validateDiscounts())) ||
                    (value === '2' &&
                      initialSubscriptionPricingBooks.pricingBookId === subscriptionPricingBooks.pricingBookId &&
                      moment(initialSubscriptionPricingBooks.startDate).format('YYYY-MM-DD') ===
                        moment(subscriptionPricingBooks.startDate).format('YYYY-MM-DD') &&
                      moment(initialSubscriptionPricingBooks.endDate).format('YYYY-MM-DD') ===
                        moment(subscriptionPricingBooks.endDate).format('YYYY-MM-DD')) ||
                    (value === '4' && parseInt(subMargin?.margin || 0) === parseInt(margin)) ||
                    discount < 0 ||
                    discount > 100 ||
                    startDate > endDate ||
                    loading == 'UPDATE_SUBSCRIPTION' ||
                    loading === 'UPDATE_NON_GOOGLE_SUBSCRIPTION' ||
                    loading === 'UPDATE_CUSTOMER_SUBSCRIPTION' ||
                    error === 'ERROR_CHECK_RECALCULATION'
                  }
                >
                  Save
                </Button>
              </>
            )}
          </DialogActions>
        </Dialog>
      </LocalizationProvider>
    </Fragment>
  )
}

export default SubscriptionEdit
