// ** React Import
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// ** MUI Imports
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import CardHeader from '@mui/material/CardHeader'
import TextField from '@mui/material/TextField'
import LinearProgress from '@mui/material/LinearProgress'
import Autocomplete from '@mui/material/Autocomplete'
import { DataGrid } from '@mui/x-data-grid'

// ** Redux Imports
import { customersSelector, fetchBoardedCustomers, getActivityLogs } from 'store/customers'
import { useDispatch, useSelector } from 'react-redux'

// ** Third Party Libraries
import moment from 'moment'
import ActivityComparaisonModal from '../components/ActivityComparaisonModal'

const ActivityLogs = () => {
  // ** Hooks
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // ** States
  const [limit, setLimit] = useState(20)
  const [page, setPage] = useState(1)
  const [customerId, setCustomerId] = useState(null)
  const [open, setOpen] = useState(false)
  const [oldValues, setOldValues] = useState({})
  const [newValues, setNewValues] = useState({})

  // ** Selectors
  const { activityLogs, boardedCustomers, pagination, loading } = useSelector(customersSelector)

  useEffect(() => {
    dispatch(fetchBoardedCustomers({ limit: 1000, page: 1, organization: '' }))
  }, [])

  // ** Get customer invoices
  useEffect(() => {
    dispatch(getActivityLogs({ limit, page, customerId }))
  }, [page, limit, customerId])

  // ** Constants
  const columns = [
    {
      flex: 0.125,
      minWidth: 250,
      field: 'createdAt',
      headerName: 'Date',
      renderCell: params => (
        <Typography noWrap variant='body2' color='black'>
          {moment(params.row.createdAt).format('YYYY-MM-DD HH:mm:ss')}
        </Typography>
      )
    },
    {
      flex: 0.125,
      minWidth: 250,
      field: 'customer',
      headerName: 'Customer',
      renderCell: params => (
        <Typography noWrap variant='body2'>
          {params.row.customer?.organization || '----'}
        </Typography>
      )
    },
    {
      flex: 0.125,
      minWidth: 250,
      field: 'model',
      headerName: 'Model',
      renderCell: params => (
        <Typography noWrap variant='body2'>
          {params.row.model}
        </Typography>
      )
    },
    {
      flex: 0.125,
      minWidth: 250,
      field: 'instance_name',
      headerName: 'scope',
      renderCell: params => (
        <Typography noWrap variant='body2'>
          {params.row.instance_name}
        </Typography>
      )
    },

    {
      flex: 0.125,
      minWidth: 250,
      field: 'old_values',
      headerName: 'Old Values',
      renderCell: params => (
        <Typography noWrap variant='body2'>
          {params.row.old_values ? JSON.stringify(params.row.old_values) : '----'}
        </Typography>
      )
    },
    {
      flex: 0.125,
      minWidth: 250,
      field: 'new_values',
      headerName: 'New Values',
      renderCell: params => (
        <Typography noWrap variant='body2'>
          {params.row.new_values ? JSON.stringify(params.row.new_values) : '----'}
        </Typography>
      )
    },
    {
      flex: 0.125,
      minWidth: 250,
      field: 'user',
      headerName: 'User',
      renderCell: params => (
        <Typography noWrap variant='body2'>
          {params.row.user}
        </Typography>
      )
    }
  ]

  // ** Functions
  const handleChangePage = newPage => {
    // Increment Page if next button Clicked and there is nextPage (returned from the api)
    setPage(++newPage)
  }

  const handleChangeRowsPerPage = rows => {
    setPage(1)
    setLimit(rows)
  }

  const filterOptions = (options, { inputValue }) => {
    return options.filter(option => {
      // Customize this part to search in multiple fields of each object
      const searchString = `${option.organization} ${option.domain}`.toLowerCase()
      return searchString.includes(inputValue.toLowerCase())
    })
  }

  return (
    <Card sx={{ boxShadow: 0, border: theme => `solid 1px ${theme.palette.grey[300]}` }}>
      <CardHeader
        sx={{
          backgroundColor: theme => theme.palette.customColors.tableHeaderBg,
          height: '65px'
        }}
        title={
          <Box sx={{ display: 'flex', alignItems: 'end', gap: 2, px: '0.25rem' }}>
            <Typography sx={{ fontWeight: 600, fontSize: '1rem' }}>Activity Logs</Typography>
          </Box>
        } 
      />
      <Box p={5} display='flex' alignItems='center' gap={5}>
        <Autocomplete
          onChange={(e, val) => setCustomerId(val ? val.id : null)}
          value={boardedCustomers?.find(el => el.id === customerId) ?? null}
          options={boardedCustomers}
          getOptionLabel={option => option?.organization}
          filterOptions={filterOptions}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              {option.organization}
            </li>
          )}
          size='small'
          sx={{ width: '100%', '.MuiAutocomplete-tag': { background: '#00000014' } }}
          renderInput={params => <TextField {...params} label='Search for customers by organization or domain' />}
        />
      </Box>
      <ActivityComparaisonModal oldValues={oldValues} newValues={newValues} open={open} setOpen={setOpen} />

      <DataGrid
        autoHeight
        disableColumnFilter
        disableColumnSelector
        disableColumnMenu
        rows={loading ? [] : activityLogs || []}
        getRowId={row => row.id}
        columns={columns}
        loading={loading}
        pagination
        pageSize={limit || 0}
        rowCount={pagination?.total || 0}
        page={pagination?.currentPage ? pagination?.currentPage - 1 : 0}
        paginationMode='server'
        rowsPerPageOptions={[0, 5, 10, 20, 50]}
        onPageSizeChange={handleChangeRowsPerPage}
        onPageChange={handleChangePage}
        onRowClick={param => {
          setNewValues(param.row.new_values)
          setOldValues(param.row.old_values)
          setOpen(true)
        }}
        components={{
          LoadingOverlay: LinearProgress
        }}
      />
    </Card>
  )
}

export default ActivityLogs
