// ** React Imports
import React, { useState } from 'react'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Card from '@mui/material/Card'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'

// ** Icon Imports
import ChevronUp from 'mdi-material-ui/ChevronUp'
import ChevronDown from 'mdi-material-ui/ChevronDown'

// Styled Card component
const StyledCard = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  border: `solid 1px ${theme.palette.grey[300]}`,
  paddingLeft: 3,
  paddingRight: 3
}))

const GoogleWorkspaceLicensing = () => {
  // ** State
  const [collapsed, setCollapsed] = useState(false)

  return (
    <StyledCard>
      <CardHeader
        title='Google Workspace Licensing'
        action={
          <IconButton
            size='small'
            aria-label='collapse'
            sx={{ color: 'text.secondary' }}
            onClick={() => setCollapsed(!collapsed)}
          >
            {!collapsed ? <ChevronDown fontSize='small' /> : <ChevronUp fontSize='small' />}
          </IconButton>
        }
      />
      <Collapse in={collapsed}>
        <CardContent></CardContent>
      </Collapse>
    </StyledCard>
  )
}

export default GoogleWorkspaceLicensing
