// ** React Import
import React, { useState } from 'react'

// ** 3rd Party Libraries
import { useDebouncedCallback } from 'use-debounce'
import moment from 'moment'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import CardHeader from '@mui/material/CardHeader'
import TextField from '@mui/material/TextField'
import Snackbar from '@mui/material/Snackbar'
import Button from '@mui/material/Button'
import LinearProgress from '@mui/material/LinearProgress'
import Grow from '@mui/material/Grow'
import Fab from '@mui/material/Fab'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import { DataGrid } from '@mui/x-data-grid'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

// ** Redux Imports
import { useGetCustomersToBillQuery } from 'store/customers/apiCaching'
import { multipleCustomersBill } from 'configs/axios/api_helper'

// ** Icon Import
import CancelIcon from 'mdi-material-ui/Close'
import EditIcon from 'mdi-material-ui/Pencil'
import ValidIcon from 'mdi-material-ui/Check'
import SyncIcon from '@mui/icons-material/Sync'

// Styled Card component
const SyncLoader = styled(SyncIcon)(({ theme }) => ({
  fontSize: 40,
  animation: 'rotateAnimation 1.5s linear infinite' /* Rotate the SVG infinitely */,
  '@keyframes rotateAnimation': {
    from: {
      transform: 'rotate(360deg)'
    },
    to: {
      transform: 'rotate(0deg)'
    }
  }
}))

const BillingCustomersTable = () => {
  // ** State
  const [selectionModel, setSelectionModel] = useState([])
  const [billingDay, setBillingDay] = useState(null)
  const [customers, setCustomers] = useState([])
  const [loading, setLoading] = useState(false)
  const [alert, setAlert] = useState({ open: false, success: false, error: false, message: '' })
  const [mode, setMode] = useState(null)
  const [dates, setDates] = useState({ start_date: null, end_date: null })
  const [status, setStatus] = useState([])
  const [billingCycle, setBillingCycle] = useState(null)
  const [autoSentFreshbooks, setAutoSentFreshbooks] = useState(null)

  // ** Queries
  const { data, isFetching, refetch } = useGetCustomersToBillQuery({
    billingDay,
    ...(billingCycle ? { billingCycle } : {}),
    ...(autoSentFreshbooks !== null ? { auto_send_to_freshbooks: autoSentFreshbooks } : {})
  })

  // ** Constants
  const columns = [
    {
      flex: 0.125,
      minWidth: 250,
      field: 'organization',
      headerName: 'Organization',
      renderCell: params => (
        <Typography noWrap variant='body2' sx={{ color: 'common.blue', cursor: 'pointer' }}>
          {params.row.organization}
        </Typography>
      )
    },
    {
      flex: 0.275,
      width: 100,
      headerName: 'From',
      field: 'from',
      renderCell: params => (
        <Box onClick={event => event.stopPropagation()}>
          {mode !== params.row.id ? (
            <Typography variant='body2' sx={{ color: 'text.primary' }}>
              {params.row.start_date ? renderNewSelectedDate(params.row.id, 'start_date') : '---'}
            </Typography>
          ) : (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label='Start Date'
                inputFormat='MM/DD/YYYY'
                value={dates.start_date}
                onChange={value => {
                  setDates({
                    start_date: new Date(value),
                    end_date: new Date(value).setDate(new Date(value).getDate() + 30)
                  })
                }}
                slotProps={{ textField: { size: 'small' } }}
                renderInput={params => <TextField {...params} onClick={event => console.log('---event---', event)} />}
              />
            </LocalizationProvider>
          )}
        </Box>
      )
    },
    {
      flex: 0.275,
      width: 100,
      headerName: 'To',
      field: 'to',
      renderCell: params => (
        <Box onClick={event => event.stopPropagation()}>
          {mode !== params.row.id ? (
            <Typography variant='body2' sx={{ color: 'text.primary' }}>
              {params.row.end_date ? renderNewSelectedDate(params.row.id, 'end_date') : '---'}
            </Typography>
          ) : (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label='End Date'
                inputFormat='MM/DD/YYYY'
                value={dates.end_date}
                onChange={value => {
                  setDates({
                    ...dates,
                    end_date: new Date(value)
                  })
                }}
                slotProps={{ textField: { size: 'small' } }}
                renderInput={params => <TextField onClick={event => event.stopPropagation()} {...params} />}
              />
            </LocalizationProvider>
          )}
        </Box>
      )
    },
    {
      flex: 0.175,
      minWidth: 140,
      field: 'billingType',
      headerName: 'Billing Type',
      renderCell: params => (
        <Typography variant='body2' sx={{ color: 'text.primary' }}>
          {params.row.billingCycle || '----'}
        </Typography>
      )
    },
    {
      flex: 0.175,
      minWidth: 140,
      field: 'auto_send_to_freshbooks',
      headerName: 'Auto sent Freshbooks',
      renderCell: params => (
        <Typography variant='body2' sx={{ color: 'text.primary' }}>
          {params.row.auto_send_to_freshbooks ? 'Yes' : 'No'}
        </Typography>
      )
    },
    {
      flex: 0.175,
      minWidth: 140,
      field: 'status',
      headerName: 'Status',
      renderCell: params => (
        <Typography variant='body2' sx={{ color: 'text.primary' }}>
          {status?.find(item => item.id === params.row.id)?.status || '----'}
        </Typography>
      )
    },
    {
      flex: 0.125,
      field: '',
      minWidth: 80,
      headerName: 'Actions',
      renderCell: params => (
        <>
          {mode !== params.row.id ? (
            <Fab color='primary' aria-label='add' size='small' onClick={event => setEditRow(event, params.row.id)}>
              <EditIcon sx={{ fontSize: 18 }} />
            </Fab>
          ) : (
            <Box display='flex' alignItems='center' gap={2}>
              <Fab
                color='primary'
                aria-label='add'
                size='small'
                onClick={event => confirmEditRow(event, params.row.id)}
              >
                <ValidIcon sx={{ fontSize: 18 }} />
              </Fab>
              <Fab color='primary' aria-label='add' size='small' onClick={event => cancelEditRow(event, params.row.id)}>
                <CancelIcon sx={{ fontSize: 18 }} />
              </Fab>
            </Box>
          )}
        </>
      )
    }
  ]

  // ** Functions
  const setEditRow = (event, id) => {
    event.stopPropagation()
    const found = customers?.find(item => item.customer_id === id)
    if (found) {
      const customer = customers?.find(item => item.customer_id === id)
      setDates({
        start_date: new Date(moment(customer.from).format('YYYY/MM/DD')),
        end_date: new Date(moment(customer.to).format('YYYY/MM/DD'))
      })
    } else {
      const customer = data?.find(item => item.id === id)
      setDates({
        start_date: new Date(moment(customer.start_date).utc(false).format('YYYY/MM/DD')),
        end_date: new Date(moment(customer.end_date).utc(false).format('YYYY/MM/DD'))
      })
    }
    setMode(id)
  }

  const cancelEditRow = (event, id) => {
    event.stopPropagation()
    setMode(null)
    // setCustomers(state => state.filter(item => item.id !== id))
  }

  const confirmEditRow = (event, id) => {
    event.stopPropagation()
    const found = customers.some(item => item.customer_id === id)
    if (found) {
      setCustomers(state =>
        state.map(customer =>
          customer.customer_id === id
            ? { customer_id: id, from: new Date(dates.start_date), to: new Date(dates.end_date) }
            : customer
        )
      )
    } else {
      setCustomers(state => [
        ...state,
        { customer_id: id, from: new Date(dates.start_date), to: new Date(dates.end_date) }
      ])
    }
    setMode(null)
    setDates({ start_date: null, end_date: null })
  }

  const renderNewSelectedDate = (id, property) => {
    const found = customers?.some(item => item.customer_id === id)
    if (found) {
      const customer = customers?.find(item => item.customer_id === id)
      return moment(customer[property === 'start_date' ? 'from' : 'to']).format('YYYY/MM/DD')
    } else {
      const customer = data?.find(item => item.id === id)
      return moment(customer[property]).format('YYYY/MM/DD')
    }
  }

  const LoadingComponent = () => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start', flexDirection: 'column' }}>
        {!loading ? <LinearProgress sx={{ width: '100% !important' }} /> : null}
        {loading ? (
          <Box display='flex' alignItems='center' flexDirection='column' gap={2} py={3}>
            <SyncLoader />
            <Typography
              sx={{
                textAlign: 'center',
                fontWeight: 550,
                fontSize: 16
              }}
              variant='body2'
            >
              This action will take a few minutes, please wait...
            </Typography>
          </Box>
        ) : null}
        {isFetching ? (
          <Typography
            sx={{
              mt: 8,
              textAlign: 'center',
              fontWeight: 550,
              fontSize: 16
            }}
            variant='body2'
          >
            Loading data...
          </Typography>
        ) : null}
      </Box>
    )
  }

  const handleCloseMessage = () => {
    setAlert({ success: false, error: false, message: '', open: false })
  }

  const onChangeBillingCycle = event => {
    setBillingCycle(event.target.value)
  }

  const onChangeAutoSent = event => {
    setAutoSentFreshbooks(event.target.value)
  }

  const debounced = useDebouncedCallback(
    event => {
      const { value } = event.target
      setBillingDay(value)
      setStatus([])
    },
    400,
    // The maximum time func is allowed to be delayed before it's invoked:
    { maxWait: 3000 }
  )
  const generateMultipleBilling = async () => {
    if (selectionModel?.length > 0) {
      const customersToBill = []
      setLoading(true)
      if (customers?.length > 0) {
        selectionModel?.map(element => {
          const edited = customers?.some(item => item.customer_id === element)
          if (edited) {
            const customer = customers?.find(item => item.customer_id === element)
            customersToBill.push({
              customer_id: customer.customer_id,
              from: moment(customer.from).format('YYYY-MM-DD'),
              to: moment(customer.to).format('YYYY-MM-DD'),
              type: data?.find(item => item.id === element)?.type === 'in advance' ? 'advance' : 'rear'
            })
          } else {
            const customer = data?.find(item => item.id === element)
            customersToBill.push({
              customer_id: customer.id,
              from: moment(customer.start_date).format('YYYY-MM-DD'),
              to: moment(customer.end_date).format('YYYY-MM-DD'),
              type: customer?.type === 'inadvance' ? 'advance' : 'rear'
            })
          }
        })
      } else {
        data
          .filter(item => selectionModel?.includes(item.id))
          ?.map(item => {
            customersToBill.push({ customer_id: item.id, from: item.start_date, to: item.end_date })
          })
      }
      try {
        const response = await multipleCustomersBill({ data: customersToBill })
        refetch()
        setLoading(false)
        setSelectionModel([])
        setStatus(response?.map(item => ({ status: item.status, id: item.customer_id })))
        setAlert({ open: true, message: 'Action was done successfully !', success: true, error: false })
      } catch (error) {
        setLoading(false)
        setAlert({ open: true, message: 'An error has occured, please try again.', success: false, error: true })
      }
    }
  }

  return (
    <Card sx={{ boxShadow: 0, border: theme => `solid 1px ${theme.palette.grey[300]}` }}>
      <CardHeader
        sx={{
          backgroundColor: theme => theme.palette.customColors.tableHeaderBg,
          height: '65px'
        }}
        title={
          <Box sx={{ display: 'flex', alignItems: 'end', gap: 2, px: '0.25rem' }}>
            <Typography sx={{ fontWeight: 600, fontSize: '1rem' }}>Billings</Typography>
            <Typography sx={{ color: 'secondary.light', fontWeight: 600 }}>| Display all customers list</Typography>
          </Box>
        }
        action={
          <Button
            disabled={!selectionModel.length || loading}
            variant='text'
            onClick={generateMultipleBilling}
            sx={{
              ml: '2rem',
              boxShadow: 0,
              '&:disabled': {
                color: theme => theme.palette.grey[700]
              }
            }}
          >
            Generate
          </Button>
        }
      />
      <Box sx={{ height: 70, px: 12, pb: 2, display: 'flex', alignItems: 'end', gap: 5 }}>
        <TextField
          type='number'
          onChange={debounced}
          label='Customer billing day'
          //variant='standard'
          size='small'
          InputProps={{ inputProps: { min: 1, max: 31 } }}
          InputLabelProps={{ style: { fontSize: '0.925rem' } }} // font size of input label
          sx={{ ml: -5, width: 350 }}
          autoFocus
        />
        <Box width={350}>
          <FormControl fullWidth size='small'>
            <InputLabel id='pricing-book-label'>Billing Cycle</InputLabel>
            <Select
              label='Billing Cycle'
              onChange={onChangeBillingCycle}
              labelId='validation-basic-select'
              //variant='standard'
            >
              <MenuItem value={null}>
                <em>None</em>
              </MenuItem>
              <MenuItem value='monthly'>Monthly</MenuItem>
              <MenuItem value='quarterly'>Quarterly</MenuItem>
              <MenuItem value='semi-yearly'>Semi-yearly</MenuItem>
              <MenuItem value='yearly'>Yearly</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box width={350}>
          <FormControl fullWidth size='small'>
            <InputLabel id='pricing-book-label'>Auto sent Freshbooks</InputLabel>
            <Select
              label='Auto sent Freshbooks'
              onChange={onChangeAutoSent}
              labelId='validation-basic-select'
              //variant='standard'
            >
              <MenuItem value={null}>
                <em>None</em>
              </MenuItem>
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      <DataGrid
        //scroll
        autoHeight
        rows={loading || isFetching ? [] : data || []}
        columns={columns}
        loading={isFetching || loading}
        checkboxSelection
        selectionModel={selectionModel}
        onSelectionModelChange={selection => {
          setSelectionModel(selection)
        }}
        pagination
        rowCount={data?.length || 0}
        paginationMode='server'
        rowsPerPageOptions={[0, 5, 10, 20, 50]}
        components={{
          LoadingOverlay: LoadingComponent
        }}
      />

      <Snackbar
        sx={{ mt: '3rem' }}
        open={alert.open}
        onClose={handleCloseMessage}
        autoHideDuration={1500}
        key={'top' + 'right'}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        TransitionComponent={Grow}
        message={alert.message}
      />
    </Card>
  )
}

export default BillingCustomersTable
