// ** React Import
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// ** MUI Imports
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import CardHeader from '@mui/material/CardHeader'
import TextField from '@mui/material/TextField'
import LinearProgress from '@mui/material/LinearProgress'
import Autocomplete from '@mui/material/Autocomplete'
import { DataGrid } from '@mui/x-data-grid'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

// ** Redux Imports
import { customersSelector, fetchBoardedCustomers, getGenerationLogs } from 'store/customers'
import { useDispatch, useSelector } from 'react-redux'

// ** Third Party Libraries
import moment from 'moment'

const GenerationsLogs = () => {
  // ** Hooks
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // ** State
  const [from, setFrom] = useState(moment())
  const [to, setTo] = useState(moment())
  const [customerId, setCustomerId] = useState(null)
  const [isGenerated, setIsGenerated] = useState(null)

  // ** Selectors
  const { generationLogs, boardedCustomers, loading } = useSelector(customersSelector)

  useEffect(() => {
    dispatch(fetchBoardedCustomers({ limit: 1000, page: 1, organization: '' }))
  }, [])
  // ** Get customer invoices
  useEffect(() => {
    dispatch(
      getGenerationLogs({
        from: from.format('YYYY-MM-DD'),
        to: to.format('YYYY-MM-DD'),
        customer_id: customerId,
        generated: isGenerated
      })
    )
  }, [from, to, customerId, isGenerated])

  // ** Constants
  const columns = [
    {
      flex: 0.125,
      minWidth: 250,
      field: 'customer_id',
      headerName: 'Organization',
      renderCell: params => (
        <Typography
          noWrap
          variant='body2'
          sx={{ color: 'common.blue', cursor: 'pointer' }}
          onClick={() => window.open('/billing/' + params.row.id)}
        >
          {boardedCustomers?.find(item => item.id == params.row.customer_id)?.organization}
        </Typography>
      )
    },
    {
      flex: 0.125,
      minWidth: 250,
      field: 'generated',
      headerName: 'Generated',
      renderCell: params => (
        <Typography noWrap variant='body2'>
          {params.row.generated ? 'Yes' : 'No'}
        </Typography>
      )
    },

    {
      flex: 0.125,
      minWidth: 250,
      field: 'day',
      headerName: 'Date',
      renderCell: params => (
        <Typography noWrap variant='body2' color='black'>
          {params.row.day}
        </Typography>
      )
    }
  ]

  const onChangeGenerated = event => {
    setIsGenerated(event.target.value)
  }

  // ** Functions

  return (
    <Card sx={{ boxShadow: 0, border: theme => `solid 1px ${theme.palette.grey[300]}` }}>
      <CardHeader
        sx={{
          backgroundColor: theme => theme.palette.customColors.tableHeaderBg,
          height: '65px'
        }}
        title={
          <Box sx={{ display: 'flex', alignItems: 'end', gap: 2, px: '0.25rem' }}>
            <Typography sx={{ fontWeight: 600, fontSize: '1rem' }}>Generation Logs</Typography>
          </Box>
        }
      />
      <Box p={5} display='flex' alignItems='center' gap={5}>
        <Autocomplete
          onChange={(e, val) => setCustomerId(val ? val.id : null)}
          value={boardedCustomers?.find(el => el.id === customerId) ?? null}
          options={boardedCustomers}
          getOptionLabel={option => option?.organization}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              {option.organization}
            </li>
          )}
          size='small'
          sx={{ width: '100%', '.MuiAutocomplete-tag': { background: '#00000014' } }}
          renderInput={params => <TextField {...params} label='Search for customers by organization or domain' />}
        />
        <Box width={500}>
          <FormControl fullWidth size='small'>
            <InputLabel id='pricing-book-label'>Generated</InputLabel>
            <Select
              label='Generated'
              onChange={onChangeGenerated}
              labelId='validation-basic-select'
              //variant='standard'
            >
              <MenuItem value={null}>
                <em>None</em>
              </MenuItem>
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Box width={350}>
            <DesktopDatePicker
              label='From'
              inputFormat='MM/DD/YYYY'
              value={from}
              onChange={newValue => setFrom(newValue)}
              sx={{ width: '100%' }}
              slotProps={{ textField: { size: 'small' } }}
              renderInput={params => <TextField {...params} />}
            />
          </Box>
          <Box width={350}>
            <DesktopDatePicker
              label='To'
              inputFormat='MM/DD/YYYY'
              value={to}
              onChange={newValue => setTo(newValue)}
              sx={{ width: '100%' }}
              slotProps={{ textField: { size: 'small' } }}
              renderInput={params => <TextField {...params} />}
            />
          </Box>
        </LocalizationProvider>
      </Box>
      <DataGrid
        autoHeight
        disableColumnFilter
        disableColumnSelector
        disableColumnMenu
        rows={loading ? [] : generationLogs?.data || []}
        getRowId={row => row.id}
        columns={columns}
        loading={loading}
        // pagination
        // pageSize={20}
        // rowCount={pagination?.total || 0}
        // page={pagination?.currentPage ? pagination?.currentPage - 1 : 0}
        // paginationMode='server'
        // rowsPerPageOptions={[0, 5, 10, 20, 50]}
        // onPageSizeChange={handleChangeRowsPerPage}
        // onPageChange={handleChangePage}
        components={{
          LoadingOverlay: LinearProgress
        }}
      />
    </Card>
  )
}

export default GenerationsLogs
