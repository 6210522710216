// ** React Imports
import React, { forwardRef, Fragment } from 'react'

// ** axios Import

// ** MUI Imports
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Slide from '@mui/material/Slide'
import Box from '@mui/material/Box'

// ** Third Party imports
import { JsonViewer } from '@textea/json-viewer'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const ActivityComparaisonModal = props => {
  // ** Props
  const { oldValues, newValues, open, setOpen } = props

  // ** Fucntions
  const handleClose = (event, reason) => {
    if (reason && reason == 'backdropClick' && 'escapeKeyDown') return
    setOpen(false)
  }

  const closeHandler = () => {
    setOpen(false)
  }

  return (
    <Fragment>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        TransitionComponent={Transition}
        disableEscapeKeyDown
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
        maxWidth='md'
        fullWidth
      >
        <DialogTitle id='alert-dialog-slide-title' sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ fontWeight: 600, fontSize: '1.5rem' }}>Compare log values</Typography>
        </DialogTitle>
        <DialogContent sx={{ p: 10, maxHeight: 'fit-content' }}>
          <Box sx={{ width: '100%', display: 'flex', gap: 10 }}>
            <Box>
              <Typography fontWeight={600} mb={4}>
                Old Values:
              </Typography>
              <JsonViewer value={oldValues} />
            </Box>
            <Box>
              <Typography fontWeight={600} mb={4}>
                New Values:
              </Typography>
              <JsonViewer value={newValues} />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions className='dialog-actions-dense'>
          <Button
            sx={{
              color: 'text.secondary',
              fontWeight: 600,
              '&:hover': {
                backgroundColor: theme => `${theme.palette.common.white} !important`,
                color: 'text.secondary'
              }
            }}
            onClick={closeHandler}
          >
            Cancel
          </Button>
          <Button
            variant='outlined'
            sx={{
              backgroundColor: 'common.blue',
              color: 'common.white',
              fontWeight: 600,
              '&:hover': {
                backgroundColor: theme => `${theme.palette.common.blue} !important`,
                color: 'common.white'
              },
              '&:disabled': {
                backgroundColor: theme => theme.palette.grey[700],
                borderColor: theme => theme.palette.grey[700]
              }
            }}
            onClick={closeHandler}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default ActivityComparaisonModal
