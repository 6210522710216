// ** React Imports
import React from 'react'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import CardContent from '@mui/material/CardContent'

// ** Third Party Libraries
import moment from 'moment'

// ** Icon Imports
import CheckIcon from 'mdi-material-ui/CheckCircleOutline'
import ErrorIcon from 'mdi-material-ui/CloseCircleOutline'

// Styled Card component
const StyledCard = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  border: `solid 1px ${theme.palette.grey[300]}`
}))

// Styled Img component
const Img = styled('img')(({ theme }) => ({
  height: 32,
  width: 32
}))

const icons = [
  { name: 'workspace', path: '/google_workspace.svg' },
  { name: 'cloud identity', path: '/cloud-identity.svg' },
  { name: 'chrome', path: '/chrome.svg' },
  { name: 'meet', path: '/meet.svg' },
  { name: 'voice', path: '/voice.svg' },
  { name: 'vault', path: '/vault.png' },
  { name: 'drive', path: '/drive.svg' },
  { name: 'cloud platform', path: '/cloud-platform.svg' }
]

const SubscriptionCardTable = props => {
  // ** Props
  const { sub, row, redirectHandler } = props

  // ** Functions
  const renderIcon = sub => {
    let name = sub?.product ? sub?.product?.productName?.toLowerCase() : sub?.skuName?.toLowerCase()
    let icon =
      icons.find(icon => name?.includes(icon?.name))?.path ??
      icons.find(icon => icon?.name?.includes('workspace'))?.path
    return <Img alt={name} src={`/google-products${icon}`} />
  }

  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={4.5}
      lg={3}
      xl={1.75}
      sx={{ maxWidth: '190px !important' }}
      onClick={() => redirectHandler(row?.id)}
    >
      <StyledCard
        sx={{
          cursor: 'pointer',
          transition: '.25s ease-in-out',
          '&:hover': {
            boxShadow: theme => theme.shadows[10]
          }
        }}
      >
        <CardContent
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            textAlign: 'center',
            height: 240,
            transition: 'all 0.1s ease-in-out'
          }}
        >
          {/* Render Icons based on product names  */}
          {renderIcon(sub)}
          <Typography sx={{ fontWeight: 600, mt: '0.725rem', fontSize: '0.875rem' }}>{sub.skuName}</Typography>
          {sub.planName && (
            <Typography sx={{ mt: '0.325rem', fontSize: '0.685rem' }}>
              {sub.planName === 'ANNUAL_YEARLY_PAY' && 'Annual plan'}
              {sub.planName === 'ANNUAL' && 'Annual plan'}
              {sub.planName === 'FREE' && 'Free plan'}
              {sub.planName === 'FLEXIBLE' && 'Flexible plan'}
              {sub.planName === 'TRIAL' && 'Trial plan'}
            </Typography>
          )}
          {row.billingCycle && <Typography sx={{ fontSize: '0.685rem' }}>({row.billingCycle + ' Payment'})</Typography>}
          {sub.endTime && (
            <Typography sx={{ fontSize: '0.685rem' }}>
              Renewel {moment(new Date(parseInt(sub?.endTime))).format('MMM DD, YYYY')}
            </Typography>
          )}
          {sub.licensedNumberOfSeats !== 0 && sub.licensedNumberOfSeats !== null ? (
            <Typography sx={{ fontSize: '0.685rem' }}>
              {sub.licensedNumberOfSeats +
                (sub.planName === 'FLEXIBLE'
                  ? sub.licensedNumberOfSeats
                    ? ' / ' + sub.licensedNumberOfSeats
                    : ''
                  : sub.numberOfSeats
                  ? ' / ' + sub.numberOfSeats
                  : '') +
                ' licenses'}
            </Typography>
          ) : null}
          {sub.customerDomain && <Typography sx={{ fontSize: '0.685rem' }}>{sub.customerDomain}</Typography>}

          <Stack direction='row' alignItems='center' gap={4} sx={{ my: 1, mt: 'auto' }}>
            {sub.status == 'ACTIVE' ? (
              <CheckIcon sx={{ color: 'green', fontSize: '0.825rem' }} />
            ) : (
              <ErrorIcon sx={{ color: 'red', fontSize: '1rem' }} />
            )}
            <Typography sx={{ fontSize: '0.685rem' }}>{sub.status}</Typography>
          </Stack>
        </CardContent>
      </StyledCard>
    </Grid>
  )
}

export default SubscriptionCardTable
