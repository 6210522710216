import moment from 'moment'

const splitArrayBySub = inputArray => {
  const groupedArrays = inputArray.reduce((result, obj) => {
    // Check if there is an array for the current subscription_id, if not, create one
    if (!result[obj.subscription_id]) {
      result[obj.subscription_id] = []
    }

    // Push the object to the corresponding subscription_id array
    result[obj.subscription_id].push(obj)

    return result
  }, {})

  // Convert the groupedArrays object to an array of arrays
  return Object.values(groupedArrays)
}

const calculateSingleRow = (prices, total, customerCurrencyId, type) => {
  if (type === 'advance') return total < 0 ? total?.toFixed(2) * -1 : total?.toFixed(2)
  const price = prices?.find(item => item.currencyId === customerCurrencyId)?.totalValue
  if (price < 0) return -1 * ((price || 0) * -1)?.toFixed(2)
  else return (price || 0)?.toFixed(2)
}

const regroupUsages = (invoiceDetails, issueDate, currencyId) => {
  // Result is to have 2 rows :
  // 1st presents the unbilled old period which is before this invoice issue date
  // 2nd presents the currenct period of the generated invoice
  const tableRows = []
  for (const item of invoiceDetails) {
    // Concatinating old usages together which is before this advance invoice issue date.
    if (new Date(issueDate) > new Date(item.day)) {
      const total = item.prices?.find(item => item.currencyId === currencyId)?.totalValue || 0
      const totalSeats = item?.seats
      tableRows.push({ ...item, total, totalSeats })
    } else {
      // Reaching the current invoice issue date we concat all rows together
      if (
        tableRows.some(el => el.subscription_id === item.subscription_id && new Date(issueDate) <= new Date(el.day))
      ) {
        const row = tableRows?.find(
          el => el.subscription_id === item.subscription_id && new Date(issueDate) <= new Date(el.day)
        ) // get existing row that will be updated

        const index = tableRows?.findIndex(
          el => el.subscription_id === item.subscription_id && new Date(issueDate) <= new Date(el.day)
        ) // index of the existing row
        const total = (item.prices?.find(item => item.currencyId === currencyId)?.totalValue || 0) + (row?.total || 0)
        tableRows[index].days = [...row?.days, ...item?.days]
        tableRows[index].intervals = [...item?.intervals, ...row?.intervals]
        tableRows[index].prices = [...item?.prices, ...row?.prices]
        tableRows[index].total = total
      } else {
        const total = item.prices?.find(item => item.currencyId === currencyId)?.totalValue || 0
        const totalSeats = item?.seats
        tableRows.push({ ...item, total, totalSeats })
      }
    }
  }
  return tableRows
}

const splitIntervals = inputArray => {
  const resultArray = []

  inputArray.forEach(item => {
    if (item.intervals?.length > 1) {
      item.intervals.forEach(interval => {
        const from = moment(interval.from)
        const to = moment(interval.to)
        const newItem = {
          ...item,
          day: interval.from,
          days: item.days?.filter(date => {
            const currentDate = moment(date)
            return currentDate.isBetween(from, to, null, '[]')
          }),
          intervals: [interval]
        }
        resultArray.push(newItem)
      })
    } else {
      resultArray.push(item)
    }
  })

  // Sort Array by sku name & day at the same time
  resultArray.sort((a, b) => {
    // Compare sku_name
    const nameComparison = a.sku_name?.localeCompare(b.sku_name)

    // If sku_name is the same, compare by day
    if (nameComparison === 0) {
      return a.intervals[0]?.from?.localeCompare(b.intervals[0]?.from)
    }

    return nameComparison
  })
  return resultArray
}

// Format discount date
const formatDiscountDate = (
  customerInformation,
  discount,
  subscription_id,
  licenceType,
  discounts,
  issue_date,
  due_date
) => {
  const totalDiscounts = discounts?.reduce((acc, { value }) => acc + parseFloat(value || 0), 0)
  let subscriptionPrice = null
  let period = null
  const currency = customerInformation?.currency
  const licenceTypeFormatted = licenceType === 'FREE' ? '' : licenceType === 'FLEXIBLE' ? 'flexible' : 'annual'
  if (!!subscription_id) {
    customerInformation?.googleIds?.forEach(googleId => {
      const subscription = googleId?.subscriptions?.find(sub => sub?.subscriptionId === subscription_id) ?? null
      if (!!subscription) {
        const customerPricingBook = customerInformation?.subscriptionPricingBooks?.find(
          item => item.subscriptionId === subscription?.subscriptionId
        )
        if (!!customerPricingBook?.pricingBookId) {
          const subscriptionEntity = subscription?.product?.prices?.find(
            item =>
              item.currencyId === currency?.id &&
              item.type === licenceTypeFormatted &&
              item.pricingBookId === customerPricingBook?.pricingBookId &&
              moment(issue_date).isSameOrAfter(moment(customerPricingBook?.startDate)) &&
              moment(due_date).isSameOrBefore(moment(customerPricingBook?.endDate))
          )

          if (subscriptionEntity?.id) {
            subscriptionPrice = subscriptionEntity?.value
          } else {
            const defaultPricing = subscription?.product?.prices?.find(
              item =>
                item.currencyId === currency?.id && item.type === licenceTypeFormatted && item.pricingBook?.is_default
            )
            subscriptionPrice = defaultPricing?.value || 0
          }
        } else {
          const subscriptionEntity = subscription?.product?.prices?.find(
            item =>
              item.currencyId === currency?.id && item.type === licenceTypeFormatted && item.pricingBook?.is_default
          )
          subscriptionPrice = subscriptionEntity?.value || 0
        }
      }
    })
  }

  const startDate = moment(discount?.startDate, 'YYYY-MM-DD')
  const endDate = moment(discount?.endDate, 'YYYY-MM-DD')

  const days = endDate.diff(startDate, 'days')

  if (days <= 30) {
    period = days > 1 ? days + ' days' : days + ' day'
  } else if (days < 365 && days > 30) {
    const months = days / 30
    period = `${parseInt(months)} ${parseInt(months) > 1 ? 'months' : 'month'}`
  } else if (days >= 365) {
    const years = days / 365
    const restDaysFromYears = days % 356
    if (restDaysFromYears > 30) {
      period = `${parseInt(years)} ${parseInt(years) > 1 ? 'years' : 'year'}`
    } else if (restDaysFromYears < 30 && restDaysFromYears > 0) {
      period = `${parseInt(years)} ${parseInt(years) > 1 ? 'years' : 'year'}`
    }
  }
  const discountText = totalDiscounts > 0 ? 'Discount: ' + totalDiscounts + '% for ' + period : ''
  const listPriceTex = subscriptionPrice
    ? (discountText ? ' (' : '(') +
      'List price: ' +
      (currency?.iso_code === 'EUR' ? '€' : '$') +
      (subscriptionPrice || 0) +
      '/user/month)'
    : ''
  return discountText + listPriceTex
}

//  Format cover period
const coverPeriod = (item, intervals, itemType, type) => {
  if (type === 'advance') {
    if (itemType == 'change') {
      if (item?.seats > 0)
        return (
          'Prorated Charge for ' +
          item?.seats +
          ' additional license' +
          (item?.seats > 1 ? 's' : '') +
          ' - ' +
          item.maxCumulatedChangeSeat +
          ' total\n' +
          'Charge to cover: ' +
          (intervals?.length > 0 && intervals?.[intervals?.length - 1]?.from + '-' + intervals?.[0]?.to)
        )
      else
        return (
          'Credit for license decrease' +
          '-' +
          item.maxCumulatedChangeSeat +
          ' total\n' +
          'Covers: ' +
          (intervals?.length > 0 && intervals?.[intervals?.length - 1]?.from + '-' + intervals?.[0]?.to)
        )
    } else
      return (
        'Charge to cover: ' +
        (intervals?.length > 0 && intervals?.[intervals?.length - 1]?.from + '-' + intervals?.[0]?.to)
      )
  }
  return (
    'Charge to cover: ' +
    (intervals?.length > 0 && intervals[intervals?.length - 1]?.from + '-' + intervals[intervals?.length - 1]?.to)
  )
}

export { formatDiscountDate, coverPeriod, splitIntervals, regroupUsages, calculateSingleRow, splitArrayBySub }
