// ** Axios
import axios from 'axios'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// ** API Imports
import {
  getAllPricingBooks,
  editMetadata,
  createPricingBook,
  updatePricingBookApi,
  updateDefaultPricingBook,
  postActivityLogs
} from 'configs/axios/api_helper'

export const initialState = {
  loading: null,
  pricingBooksData: [],
  success: '',
  error: '',
  pagination: {
    nextPage: null,
    previousPage: null,
    limit: 0,
    total: 0,
    currentPage: 1
  }
}
// A slice
const pricingBooksSlice = createSlice({
  name: 'pricingbookData',
  initialState,
  reducers: {
    addPricingBook: (state, action) => {
      state.pricingBooksData.push(action.payload)
      state.success = 'CREATE'
      state.loading = null
    },
    updatePricingBook: (state, action) => {
      state.pricingBooksData = state.pricingBooksData.map(pricing =>
        pricing.id.toString() === action.payload.id.toString() ? { ...pricing, ...action.payload } : pricing
      )
      state.success = 'UPDATE'
      state.loading = null
    },
    removePricingBook: (state, action) => {
      state.pricingBooksData = state.pricingBooksData.filter(pricing => pricing.id !== action.payload)
      state.success = 'DELETE'
      state.loading = null
    },
    startLoading: (state, action) => {
      state.loading = action.payload
      state.error = ''
      state.success = ''
    },
    getPricingBooksDataSuccess: (state, action) => {
      const { data, ...filters } = action.payload
      state.pricingBooksData = data
      state.loading = null
      state.pagination = filters
      state.success = 'GET'
    },
    getPricingBooksDataFailure: state => {
      state.loading = null
      //handling Errors
      state.error = 'GET'
    },
    resetMessages: state => {
      state.success = ''
      state.error = ''
      state.loading = null
    }
  }
})

// Actions generated from the slice
const {
  addPricingBook,
  updatePricingBook,
  removePricingBook,
  startLoading,
  resetMessages,
  getPricingBooksDataFailure,
  getPricingBooksDataSuccess
} = pricingBooksSlice.actions

// export user selector to get the slice in any component
export const pricingBooksSelector = state => state.pricingBooksData

// export The reducer
const pricingBooksReducer = pricingBooksSlice.reducer
export default pricingBooksReducer

// Actions
export const fetchPricingbooksData =
  ({ status = '', page = '', limit = '' } = {}) =>
  async dispatch => {
    try {
      dispatch(startLoading('GET'))
      const response = await getAllPricingBooks({ status })
      const { data } = response
      dispatch(getPricingBooksDataSuccess(data ?? []))
    } catch (error) {
      dispatch(getPricingBooksDataFailure())
    }
  }

export const createPricingbook =
  ({ pricingBook, navigate }) =>
  async dispatch => {
    try {
      dispatch(startLoading('CREATE'))
      const data = await createPricingBook({
        ...pricingBook,
        type: 'pricing_book',
        status: pricingBook.status || 'inactive'
      })
      if (pricingBook.is_default) {
        const isDefaultResponse = await updateDefaultPricingBook({ pricingBookId: data?.id })
      }
      dispatch(addPricingBook(data))
      navigate('/pricing-books')
    } catch (error) {
      dispatch(getPricingBooksDataFailure())
    }
  }

export const deletePricingbook = id => async dispatch => {
  try {
    dispatch(startLoading('DELETE'))
    const response = await editMetadata(id, { status: 'archived' })
    dispatch(removePricingBook(id))
  } catch (error) {
    dispatch(getPricingBooksDataFailure())
  }
}

export const editPricingBook = (pricingBook, log) => async dispatch => {
  try {
    let data = null
    const { id, ...rest } = pricingBook
    dispatch(startLoading('UPDATE'))
    data = await updatePricingBookApi(id, { ...rest })
    if (rest.is_default) {
      data = await updateDefaultPricingBook({ pricingBookId: id })
    }
    dispatch(updatePricingBook(data))
    log && postActivityLogs(log)
  } catch (error) {
    dispatch(getPricingBooksDataFailure())
  }
}

export const cleanMessages = () => dispatch => {
  dispatch(resetMessages())
}
