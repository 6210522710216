// ** React Imports
import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import CircularProgress from '@mui/material/CircularProgress'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'

// ** Third Party Imports
import * as yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import PropTypes from 'prop-types'

// ** Custom Components Imports
import FormInput from '../components/FormInput'
import DeleteProductModal from '../components/DeleteProductDialog'

// ** Styled Components
import FallbackSpinner from 'core/components/spinner'
import AlertMessages from 'pages/Billing/components/AlertMessages'

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'
import { productsSelector, getProductsData, cleanMessages } from 'store/products'
import TableFormPricing from '../components/TableFormPricing'
import TableFormProducts from '../components/TableFormProducts'
import DatePickerForm from '../components/DatePickerForm'
import SelectForm from '../components/SelectForm'
import moment from 'moment'
import {
  createPricingbook,
  editPricingBook,
  fetchPricingbooksData,
  pricingBooksSelector
} from 'store/metaData/pricingbook'
import SwitchForm from '../components/SwitchForm'
import { useAuth } from 'hooks/useAuth'

// ** Custom Field Form

const schema = yup.object().shape({
  name: yup.string().required('Pricing name is required')
})

const Container = styled(Grid)(({ theme }) => ({
  marginTop: 5,
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0)
  },
  [theme.breakpoints.down('xs')]: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0)
  }
}))

const initialValues = {
  name: '',
  from: new Date(),
  to: new Date(),
  status: 'active',
  is_default: false,
  prices: []
}

const findMissingObjects = (array1, array2) => {
  // Create a map of unique object identifiers from array2
  const objectMap = new Map()
  for (const obj of array2) {
    if (!!obj.id) objectMap.set(obj.id, true) // Assuming objects have an "id" property
  }

  // Filter array1 to get objects that are missing in array2
  const missingObjects = array1.filter(obj => !objectMap.has(obj.id))

  return missingObjects
}
const removeDuplicatesByProperty = (array, property) => {
  const seenIds = new Set()
  return array.filter(obj => {
    if (!seenIds.has(obj[property])) {
      seenIds.add(obj[property])
      return true
    }
    return false
  })
}

const findEditedObjects = (originalArray, editedArray) => {
  const editedObjects = []

  for (const editedObj of editedArray) {
    const matchingOriginalObj = originalArray.find(originalObj => originalObj.id === editedObj.id)

    if (!matchingOriginalObj) {
      // If there's no matching object in the original array, consider it edited
      editedObjects.push(editedObj)
    } else {
      // Compare properties to find edited values
      for (const key in editedObj) {
        if (editedObj[key] !== matchingOriginalObj[key]) {
          editedObjects.push(editedObj)
          break // Stop comparing if an edit is found
        }
      }
    }
  }

  return editedObjects
}
const PricingBookForm = () => {
  // ** Hooks
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()
  const { user } = useAuth()

  // ** Selectors
  const { pricingBooksData, success, error, loading } = useSelector(pricingBooksSelector)
  const { products } = useSelector(productsSelector)
  // ** Hooks
  const {
    reset,
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: initialValues,
    mode: 'onChange',
    resolver: yupResolver(schema)
  })

  // ** State
  const [previousPrices, setPreviousPrices] = useState([])
  const [previousProducts, setPreviousProducts] = useState([])

  // ** Fetch products
  useEffect(() => {
    if ((id && pricingBooksData.length < 1) || success === 'UPDATE' || success === 'CREATE') {
      dispatch(fetchPricingbooksData())
    }
    if (products?.length < 1) dispatch(getProductsData({ limit: 1000, page: 1, field: '' }))
  }, [id, success])

  const previousPricesHandler = useMemo(() => {
    if (!id && pricingBooksData?.length > 0) {
      const data = pricingBooksData?.find(item => item?.is_default)
      const prices = data?.prices?.map(item => ({ ...item, type: item.type === 'annual' ? 'commitment' : item.type }))
      setPreviousPrices(prices || [])
      const previous_products = prices?.length > 0 ? removeDuplicatesByProperty(data.prices, 'productId') : []
      setPreviousProducts(previous_products)
      reset({
        ...initialValues,
        prices: prices
      })
    }
    if (pricingBooksData?.length > 0 && id) {
      const data = pricingBooksData?.find(item => item.id.toString() === id.toString())
      const prices = data?.prices?.map(item => ({ ...item, type: item.type === 'annual' ? 'commitment' : item.type }))
      setPreviousPrices(prices || [])
      const previous_products = prices?.length > 0 ? removeDuplicatesByProperty(data.prices, 'productId') : []
      setPreviousProducts(previous_products)
      reset({
        name: data.name,
        from: new Date(data.from),
        to: new Date(data.to),
        status: data.status,
        is_default: data.is_default,
        prices: prices
      })
    }
  }, [pricingBooksData])

  // ** Functions
  const cancelHandler = () => navigate('/pricing-books')

  const onSubmit = (data, e) => {
    const from_date = moment(data.from).format('YYYY-MM-DD')
    const to_date = moment(data.to).format('YYYY-MM-DD')
    const new_prices = findEditedObjects(previousPrices, data?.prices)?.map(item => {
      const { updatedAt, createAt, ...rest } = item
      return rest
    })
    const deleted_prices = findMissingObjects(previousPrices, data.prices)?.map(item => item.id) || []
    const body = {
      ...(id ? { id: id } : {}),
      name: data.name,
      from: from_date,
      to: to_date,
      type: 'pricing_book',
      status: data.status,
      is_default: data.is_default,
      ...(new_prices?.length > 0
        ? id
          ? { prices: new_prices }
          : {
              prices: data?.prices?.map(item => ({
                type: item.type?.toLowerCase() === 'commitment' ? 'annual' : item.type,
                value: item.value,
                productId: item.productId,
                currencyId: item.currencyId
              }))
            }
        : {}),
      ...(id && deleted_prices?.length > 0 ? { deletedPrices: deleted_prices } : {})
    }
    if (id) {
      const data = pricingBooksData?.find(item => item.id.toString() === id.toString())
      const log = {
        customer_id: id,
        date: new Date(),
        user: user.email,
        model: 'pricingBook',
        instance_name: 'prices',
        old_values: {
          pricingBook: data
        },
        new_values: {
          pricingBook: body
        }
      }
      dispatch(editPricingBook(body, log))
    } else {
      dispatch(createPricingbook({ pricingBook: body, navigate }))
    }
  }

  const resetMessagesHandler = () => dispatch(cleanMessages())

  return (
    <Card
      sx={{
        boxShadow: 0,
        border: theme => `solid 1px ${theme.palette.grey[300]}`,
        py: 10,
        px: 5,
        minHeight: 'calc(100vh - 90px)'
      }}
    >
      {loading == 'GET_PRODUCT' && <FallbackSpinner />}
      {!loading !== 'GET_PRODUCT' && (
        <form onSubmit={handleSubmit(onSubmit)} id='subscription-form'>
          <Container container spacing={5}>
            <Grid item xs={12} display='flex' justifyContent='space-between' mb={5}>
              <Typography variant='h5' sx={{ mb: '1rem' }}>
                Pricing informations
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: `${id ? 'space-between' : 'end'}`, mt: 'auto' }}>
                {id && <Box>{/* <DeleteProductModal productId={id} /> */}</Box>}
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                  <Button
                    size='medium'
                    variant='text'
                    sx={{ color: theme => theme.palette.secondary.light, mx: '0.5rem', fontWeight: 600 }}
                    onClick={cancelHandler}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={loading === 'CREATE' || loading === 'UPDATE'}
                    startIcon={
                      (loading === 'CREATE' || loading === 'UPDATE') && (
                        <CircularProgress sx={{ color: 'common.white' }} size='1rem' />
                      )
                    }
                    sx={{
                      borderRadius: '3px',
                      '&:hover': { backgroundColor: 'common.blue' },
                      '&:disabled': { backgroundColor: theme => theme.palette.secondary.light }
                    }}
                    size='medium'
                    type='submit'
                    form='subscription-form'
                    variant='contained'
                  >
                    {id ? 'Update' : 'Create'}
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} lg={3}>
              <FormInput
                control={control}
                errors={errors}
                fullWidth
                name='name'
                placeholder='pricing book name...'
                label='Name'
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <DatePickerForm
                control={control}
                errors={errors}
                fullWidth
                name='from'
                label='From'
                maxDate={watch('to')}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <DatePickerForm
                control={control}
                errors={errors}
                fullWidth
                name='to'
                label='To'
                minDate={watch('from')}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <SelectForm
                control={control}
                errors={errors}
                fullWidth
                name='status'
                placeholder='status...'
                label='Status'
                options={[
                  { value: 'active', label: 'Active' },
                  { value: 'inactive', label: 'Inactive' }
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <TableFormProducts
                setValue={setValue}
                previousPrices={previousPrices}
                oldProducts={previousProducts}
                products={products}
              />
            </Grid>
          </Container>
        </form>
      )}
      <AlertMessages
        success={success}
        error={error}
        messageValue={success === 'CREATE' ? 'CREATE' : 'UPDATE'}
        message={
          (success === 'UPDATE' && 'Pricing book was updated successfully !') ||
          (success === 'CREATE' && 'Pricing book was created successfully !') ||
          (error && error.message)
        }
        resetHandler={resetMessagesHandler}
      />
    </Card>
  )
}

export default PricingBookForm
