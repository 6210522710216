// ** React Imports
import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

// ** Apis Calls
import { createFreshbooksId } from 'configs/axios/api_helper'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Select from '@mui/material/Select'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import OutlinedInput from '@mui/material/OutlinedInput'
import FormHelperText from '@mui/material/FormHelperText'
import Stack from '@mui/material/Stack'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import FormControlLabel from '@mui/material/FormControlLabel'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

// ** Third Party Imports
import * as yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import moment from 'moment'

// ** Styled Components
import FallbackSpinner from 'core/components/spinner'
import AlertMessages from 'pages/Billing/components/AlertMessages'

// ** Modals Imports
import FreshbooksOrganization from '../modals/FreshbooksOrganization'

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'
import { apiSlice } from 'store/customers/apiCaching'
import { createCustomer, customersSelector, editCustomer } from 'store/customers'
import { currencySelector, fetchCurrencyData } from 'store/metaData/currency'

// ** Countries code Import
import { countries } from '@fake-db/countryCode'
import { timezone } from '@fake-db/timezone'
import AutocompleteForm from '../forms/AutocompleteForm'

const showErrors = (field, valueLen, min) => {
  if (valueLen === 0) {
    return `${field} field is required`
  } else if (valueLen > 0 && valueLen < min) {
    return `${field} must be at least ${min} characters`
  } else {
    return ''
  }
}

const Container = styled(Grid)(({ theme }) => ({
  marginTop: 5,
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0)
  },
  [theme.breakpoints.down('xs')]: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0)
  }
}))

const initialValues = {
  organization: '',
  googleId: '',
  firstname: '',
  lastname: '',
  email: '',
  domain: '',
  status: 'active',
  phone: '',
  languageCode: 'en',
  countryCode: '',
  freshbooksId: '',
  regionCode: '',
  locality: '',
  contactFirstName: '',
  contactLastName: '',
  contactEmail: '',
  contactPhone: '',
  contactTitle: '',
  selectedFreeDomain: '',
  is_free_domain: false,
  googleIds: [],
  discounts: [],
  contacts: [],
  taxes: [],
  currencyId: null,
  startBillingDate: null,
  first_billing_date: null,
  timezoneOffset: timezone.find(item => item.utc.includes(Intl.DateTimeFormat().resolvedOptions().timeZone))
}

const CustomerInformations = () => {
  // ** Hooks
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const customersType = pathname.split('/')[1]
  const page = pathname.split('/')[2]

  // ** State
  const [freshbooksOrg, setFreshBooksOrg] = useState(null)
  const [freshbooksError, setFreshbooksError] = useState(false)

  // ** Selectors
  const { customerInformation, success, error, loading } = useSelector(customersSelector)
  const { currenciesData } = useSelector(currencySelector)

  // ** Constants
  const schema = yup.object().shape({
    contactEmail: yup.string().email().required("Contact's email is required.").typeError('This email must be valid.'),
    contactFirstName: yup.string().required("Contact's first name is required."),
    contactLastName: yup.string().required("Contact's last name field is required."),
    ...(page === 'new-customer' || customersType == 'google-customers'
      ? {
          startBillingDate: yup
            .string('Start billing date is required')
            .required('Start billing date is required.')
            .typeError('Start billing date is required.')
        }
      : {}),
    timezoneOffset: yup.mixed().required('Timezone field is required.'),
    organization: yup
      .string()
      .required()
      .min(3, obj => showErrors('organization', obj.value.length, obj.min))
      .nullable(),
    domain: yup
      .string()
      .min(3, obj => showErrors('domain', obj.value.length, obj.min))
      .required()
      .matches(
        '^(?:(?:[a-zA-Z0-9](?:[-a-zA-Z0-9]*[a-zA-Z0-9])?.)+[a-zA-Z]{2,}|(?:d{1,3}.){3}d{1,3})(?::d+)?$',
        'Please enter a valid domain'
      )
  })

  // ** Hooks
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    watch
  } = useForm({
    defaultValues: useMemo(() => {
      return initialValues
    }, [initialValues]),
    mode: 'onChange',
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    if (!(page === 'new-customer')) {
      if (customerInformation != undefined) {
        dispatch(fetchCurrencyData())
        //Get The first contact created (oldestdate) which is provided from google when boarding the customer
        let superContact = {}
        if (customersType == 'google-customers') {
          // Case which we got contact entity for the first time
          superContact = {
            ...(customerInformation?.contactFirstName && { contactFirstName: customerInformation?.contactFirstName }),
            ...(customerInformation?.contactLastName && { contactLastName: customerInformation?.contactLastName }),
            ...(customerInformation?.contactEmail && { contactEmail: customerInformation?.contactEmail }),
            ...(customerInformation?.contactPhone && { contactPhone: customerInformation?.contactPhone }),
            ...(customerInformation?.contactTitle && { contactTitle: customerInformation?.contactTitle }),
            ...(customerInformation?.contactDisplayName && {
              contactDisplayName: customerInformation?.contactDisplayName
            })
          }
          reset({
            ...customerInformation,
            ...(superContact != undefined && {
              contactId: superContact?.id,
              contactFirstName: superContact?.contactFirstName || '',
              contactLastName: superContact?.contactLastName || '',
              contactEmail: superContact?.contactEmail || '',
              contactPhone: superContact?.contactPhone || '',
              contactTitle: superContact?.contactTitle || ''
            })
          })
        } else if (customerInformation?.contacts?.length > 0 && customerInformation?.contacts != undefined) {
          superContact = customerInformation?.contacts?.reduce((oldest, current) =>
            current.createdAt < oldest.createdAt ? current : oldest
          )
          reset({
            ...customerInformation,
            timezoneOffset: customerInformation?.timezoneOffset
              ? timezone?.find(time => time.offset === customerInformation?.timezoneOffset / 60)
              : timezone.find(item => item.utc.includes(Intl.DateTimeFormat().resolvedOptions().timeZone)),
            ...(superContact != undefined && {
              contactId: superContact?.id,
              contactFirstName: superContact?.firstname,
              contactLastName: superContact?.lastname,
              contactEmail: superContact?.email,
              contactPhone: superContact?.phone,
              contactTitle: superContact?.title
            })
          })
        } else {
          reset({ ...customerInformation })
        }
      }
      // merge the contact information in addition to the customerInformation in the global object of customer information
    }
    return () => {
      reset({ ...initialValues })
    }
  }, [customerInformation])

  // ** Functions

  const freshbooksIdOnChangeHandler = org => {
    setFreshBooksOrg({ ...org })
    setValue('freshbooksId', org.id)
    setFreshbooksError(false)
  }

  const handleFreshbooksCreate = async () => {
    // Destructure specific values from the form to create a new freshbooks entity
    let customer = getValues()
    let newFreshbooks = {
      fName: customer.firstname,
      lName: customer.lastname,
      organization: customer.organization,
      email: customer.email,
      busPhone: customer.phone,
      pCountry: countries.find(country => country.code == customer.countryCode)?.name,
      pStreet: customer.streetAddress,
      pCity: customer.locality,
      pProvince: customer.state,
      pCode: customer.zipCode,
      allowLateNotifications: true
    }
    const data = await createFreshbooksId(newFreshbooks)
    if (data?.id) {
      // If the creation of the freshbooks was successfull
      setFreshBooksOrg({ ...data })
      setValue('freshbooksId', data.id)
      setFreshbooksError(false)
    }
  }

  const onChangeFreeDomain = (event, onChange) => {
    onChange(event)
    if (event.target.value) {
      const domain = customerInformation?.googleIds?.find(item => item.id === event.target.value)
      if (domain?.id) setValue('is_free_domain', domain?.is_free_domain)
    }
  }

  const onSubmit = (data, e) => {
    //format body
    const body = {
      ...data,
      timezoneOffset: isNaN(data?.timezoneOffset) ? (data?.timezoneOffset?.offset || 0) * 60 : data?.timezoneOffset,
      startBillingDate: moment(data.startBillingDate).format('YYYY-MM-DD'),
      first_billing_date: moment(data.first_billing_date).format('YYYY-MM-DD')
    }
    // If customer is unboarded we dispatch the creation method else case we are updating it's value after it was created
    if (!data.freshbooksId) {
      setFreshbooksError(true)
    } else {
      if (page == 'new-customer') {
        dispatch(createCustomer(body, navigate))
        dispatch(apiSlice.util.resetApiState())
        // dispatch(apiSlice.util.invalidateTags(['CustomersList']))
      } else if (customersType == 'google-customers') {
        dispatch(createCustomer(body, navigate))
        dispatch(apiSlice.util.resetApiState())
      } else {
        dispatch(editCustomer(body))
      }
    }
  }

  return (
    <Fragment>
      {loading == 'GET_CUSTOMER_ONBOARDING' && <FallbackSpinner />}
      {!(loading && loading == 'GET_CUSTOMER_ONBOARDING') && (
        <form onSubmit={handleSubmit(onSubmit)} id='customer-information'>
          <Container container spacing={5} sx={{ px: '25%' }}>
            <Grid item xs={12}>
              <Typography variant='h6'>Buisness Type</Typography>
              <Stack direction='row' alignItems='center' sx={{ mt: '0.5rem', ml: '-0.725rem' }}>
                <Checkbox />
                <Typography variant='body2'>Google Workspace Customer</Typography>
              </Stack>
            </Grid>
            {page === 'new-customer' ? (
              <Grid item xs={12}>
                <Typography variant='h6' sx={{ mb: '1rem' }}>
                  Google ID
                </Typography>
                <FormControl fullWidth>
                  <Controller
                    name='googleId'
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        value={value ?? ''}
                        label='Google ID'
                        onChange={onChange}
                        placeholder='999999'
                        error={Boolean(errors.googleId)}
                        aria-describedby='validation-basic-googleId'
                        InputLabelProps={{
                          sx: {
                            color: theme => theme.palette.grey[500]
                          }
                        }}
                      />
                    )}
                  />
                  {errors.googleId && (
                    <FormHelperText sx={{ color: 'error.main' }} id='validation-basic-googleId'>
                      This field is required
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <Typography variant='h6' sx={{ mb: '1rem' }}>
                Organization Name
              </Typography>
              <FormControl fullWidth>
                <Controller
                  name='organization'
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      value={value ?? ''}
                      label='Organization Name'
                      onChange={onChange}
                      placeholder='Anaconda, Inc'
                      error={Boolean(errors.organization)}
                      aria-describedby='validation-basic-organization'
                      InputLabelProps={{
                        sx: {
                          color: theme => theme.palette.grey[500]
                        }
                      }}
                    />
                  )}
                />
                {errors.organization && (
                  <FormHelperText sx={{ color: 'error.main' }} id='validation-basic-organization'>
                    {errors.organization.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <Controller
                  name='domain'
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      value={value ?? ''}
                      label='Domain'
                      onChange={onChange}
                      placeholder='Carter'
                      error={Boolean(errors.domain)}
                      aria-describedby='validation-basic-domain'
                      InputLabelProps={{
                        sx: {
                          color: theme => theme.palette.grey[500]
                        }
                      }}
                    />
                  )}
                />
                {errors.domain && (
                  <FormHelperText sx={{ color: 'error.main' }} id='validation-basic-last-name'>
                    {errors.domain.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            {page !== 'new-customer' && customersType !== 'google-customers' ? (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel
                    id='exiting-domains-label'
                    error={Boolean(errors.select)}
                    htmlFor='validation-basic-select'
                    sx={{ color: theme => theme.palette.grey[500] }}
                  >
                    Existing domains
                  </InputLabel>
                  <Controller
                    name='selectedFreeDomain'
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <Select
                        value={value ?? ''}
                        label='Existing domains'
                        onChange={event => onChangeFreeDomain(event, onChange)}
                        labelId='existing-domains-label'
                      >
                        <MenuItem value={null} disabled>
                          <em>None</em>
                        </MenuItem>
                        {customerInformation?.googleIds?.map(item => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.domain}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
            ) : null}
            {customersType === 'google-customers' || watch('selectedFreeDomain') ? (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Controller
                    name='is_free_domain'
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        label='Is free domain'
                        control={<Checkbox {...field} checked={!!field.value} name='free-domain-checkbox' />}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            ) : null}

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel
                  id='validation-basic-language-code'
                  error={Boolean(errors.select)}
                  htmlFor='validation-basic-select'
                  sx={{ color: theme => theme.palette.grey[500] }}
                >
                  Language Code
                </InputLabel>
                <Controller
                  name='languageCode'
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange } }) => (
                    <Select
                      value={value ?? ''}
                      label='Language Code'
                      onChange={onChange}
                      error={Boolean(errors.languageCode)}
                      labelId='validation-basic-language-code'
                      aria-describedby='validation-basic-language-code'
                    >
                      <MenuItem value='en'>English</MenuItem>
                      <MenuItem value='fr'>French</MenuItem>
                    </Select>
                  )}
                />
                {errors.languageCode && (
                  <FormHelperText sx={{ color: 'error.main' }} id='validation-schema-language-code'>
                    {errors.languageCode.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <Controller
                  name='countryCode'
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange } }) => (
                    <Autocomplete
                      defaultValue={countries?.find(
                        country => country?.code === (page !== 'new-customer' ? customerInformation?.countryCode : '')
                      )}
                      value={countries?.find(country => country?.code === value)}
                      onChange={(event, values) => onChange(values?.code)}
                      options={countries}
                      getOptionLabel={option => option.name}
                      renderInput={params => <TextField {...params} label='Country / Region' />}
                    />
                  )}
                />
                {errors.countryCode && (
                  <FormHelperText sx={{ color: 'error.main' }} id='validation-schema-region-code'>
                    {errors.countryCode.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <AutocompleteForm
                control={control}
                errors={errors}
                fullWidth
                name='timezoneOffset'
                placeholder='time zone...'
                label='Time zone'
                options={timezone || []}
                getOptionLabel={option => option.text || ''}
                isOptionEqualToValue={(option, value) => option.value === value.value}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <Controller
                  name='streetAddress'
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      value={value ?? ''}
                      label='Street address'
                      onChange={onChange}
                      placeholder='Carter'
                      error={Boolean(errors.streetAddress)}
                      aria-describedby='validation-basic-street-address'
                      InputLabelProps={{
                        sx: {
                          color: theme => theme.palette.grey[500]
                        }
                      }}
                    />
                  )}
                />
                {errors.streetAddress && (
                  <FormHelperText sx={{ color: 'error.main' }} id='validation-basic-street-address'>
                    This field is required
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <Controller
                  name='locality'
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      value={value ?? ''}
                      label='City'
                      onChange={onChange}
                      placeholder='Montreal'
                      error={Boolean(errors.locality)}
                      aria-describedby='validation-basic-city'
                      InputLabelProps={{
                        sx: {
                          color: theme => theme.palette.grey[500]
                        }
                      }}
                    />
                  )}
                />
                {errors.locality && (
                  <FormHelperText sx={{ color: 'error.main' }} id='validation-basic-city'>
                    This field is required
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item container xs={12} spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <Controller
                    name='state'
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        value={value ?? ''}
                        label='State'
                        onChange={onChange}
                        placeholder='...'
                        error={Boolean(errors.state)}
                        aria-describedby='validation-basic-state'
                        InputLabelProps={{
                          sx: {
                            color: theme => theme.palette.grey[500]
                          }
                        }}
                      />
                    )}
                  />
                  {errors.state && (
                    <FormHelperText sx={{ color: 'error.main' }} id='validation-basic-state'>
                      This field is required
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <Controller
                    name='zipCode'
                    control={control}
                    rules={{ required: false }}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        value={value ?? ''}
                        label='ZIP code'
                        onChange={onChange}
                        placeholder='Carter'
                        error={Boolean(errors.zipCode)}
                        aria-describedby='validation-schema-zip-code'
                        InputLabelProps={{
                          sx: {
                            color: theme => theme.palette.grey[500]
                          }
                        }}
                      />
                    )}
                  />
                  {errors.zipCode && (
                    <FormHelperText sx={{ color: 'error.main' }} id='validation-schema-zip-code'>
                      This field is required
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            {customersType == 'google-customers' || page === 'new-customer' ? (
              <>
                <Grid item xs={12}>
                  <Typography variant='h6'>Billing Information</Typography>
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel
                      id='currency-select'
                      error={Boolean(errors.currency)}
                      htmlFor='currency-select'
                      sx={{ color: theme => theme.palette.grey[500] }}
                    >
                      Currency
                    </InputLabel>
                    <Controller
                      name='currencyId'
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { value, onChange } }) => (
                        <Select
                          value={value}
                          label='Currency'
                          onChange={onChange}
                          error={Boolean(errors.currencyId)}
                          labelId='currency-select'
                          aria-describedby='currency-select'
                        >
                          <MenuItem value={null} disabled>
                            <em>None</em>
                          </MenuItem>
                          {currenciesData.map(currency => (
                            <MenuItem key={currency.id} value={currency.id}>
                              {currency.iso_code}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    {errors.currencyId && (
                      <FormHelperText sx={{ color: 'error.main' }} id='validation-schema-currencyId'>
                        {errors.currencyId.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <FormControl fullWidth>
                      <Controller
                        name='startBillingDate'
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <DesktopDatePicker
                            label='Start billing date'
                            value={value ?? null}
                            inputFormat='MM/DD/YYYY'
                            onChange={onChange}
                            sx={{ width: '100%' }}
                            slotProps={{ textField: { size: 'medium', error: Boolean(errors.startBillingDate) } }}
                            renderInput={params => <TextField {...params} fullWidth />}
                          />
                        )}
                      />
                      {errors.startBillingDate && (
                        <FormHelperText sx={{ color: 'error.main' }} id='validation-schema-startBillingDate'>
                          This field is required
                        </FormHelperText>
                      )}
                    </FormControl>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <FormControl fullWidth>
                      <Controller
                        name='first_billing_date'
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <DesktopDatePicker
                            label='First billing date'
                            value={value ?? null}
                            inputFormat='MM/DD/YYYY'
                            onChange={onChange}
                            sx={{ width: '100%' }}
                            slotProps={{ textField: { size: 'medium', error: Boolean(errors.first_billing_date) } }}
                            renderInput={params => <TextField {...params} fullWidth />}
                          />
                        )}
                      />
                      {errors.first_billing_date && (
                        <FormHelperText sx={{ color: 'error.main' }} id='validation-schema-first_billing_date'>
                          This field is required
                        </FormHelperText>
                      )}
                    </FormControl>
                  </LocalizationProvider>
                </Grid>
              </>
            ) : null}
            <Grid item xs={12}>
              <Typography variant='h6' sx={{ mb: '1.25rem' }}>
                Contact Information
              </Typography>
              <Typography variant='body2'>
                The name and email address are used to create the initial account for Google Workspace, Cloud Identity,
                and Chrome Entrprise Upgrade orders. <Typography sx={{ color: 'common.blue' }}>Learn More</Typography>
              </Typography>
              <Typography variant='body2' sx={{ my: 5 }}>
                Changes to customer contact information will not reflected in existing subscriptions
              </Typography>
            </Grid>
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <Controller
                    name='contactFirstName'
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        value={value ?? ''}
                        label='First Name'
                        onChange={onChange}
                        placeholder='Jhon'
                        error={Boolean(errors.contactFirstName)}
                        aria-describedby='validation-basic-contactFirstName'
                        InputLabelProps={{
                          sx: {
                            color: theme => theme.palette.grey[500]
                          }
                        }}
                      />
                    )}
                  />
                  {errors.contactFirstName && (
                    <FormHelperText sx={{ color: 'error.main' }} id='validation-schema-contactFirstName'>
                      {errors.contactFirstName.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <Controller
                    name='contactLastName'
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        value={value ?? ''}
                        label='Last name'
                        onChange={onChange}
                        placeholder='Carter'
                        error={Boolean(errors.contactLastName)}
                        aria-describedby='validation-schema-contactLastName'
                        InputLabelProps={{
                          sx: {
                            color: theme => theme.palette.grey[500]
                          }
                        }}
                      />
                    )}
                  />
                  {errors.contactLastName && (
                    <FormHelperText sx={{ color: 'error.main' }} id='validation-basic-contactLastName'>
                      {errors.contactLastName.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Controller
                  name='contactEmail'
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange } }) => (
                    <OutlinedInput
                      value={value ?? ''}
                      onChange={onChange}
                      placeholder='Email'
                      error={Boolean(errors.contactEmail)}
                      aria-describedby='validation-schema-contactEmail'
                      // endAdornment={<InputAdornment position='end'>@anaconda.com</InputAdornment>}
                      InputLabelProps={{
                        sx: {
                          color: theme => theme.palette.grey[500]
                        }
                      }}
                    />
                  )}
                />
                {errors.contactEmail && (
                  <FormHelperText sx={{ color: 'error.main' }} id='validation-schema-contactEmail'>
                    {errors.contactEmail.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <Controller
                    name='contactTitle'
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        value={value ?? ''}
                        label='Title'
                        onChange={onChange}
                        placeholder='Carter'
                        error={Boolean(errors.contactTitle)}
                        aria-describedby='validation-schema-contactTitle'
                        InputLabelProps={{
                          sx: {
                            color: theme => theme.palette.grey[500]
                          }
                        }}
                      />
                    )}
                  />
                  {/* <FormHelperText id='icons-weight-helper-text'>Enter any email address that doesn't use the exclaimer primary domain.</FormHelperText> */}
                  {errors.contactTitle && (
                    <FormHelperText sx={{ color: 'error.main' }} id='validation-schema-contactTitle'>
                      This field is required
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <Controller
                    name='contactPhone'
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        value={value ?? ''}
                        label='Phone'
                        onChange={onChange}
                        placeholder='Carter'
                        error={Boolean(errors.contactPhone)}
                        aria-describedby='validation-schema-contactPhone'
                        InputLabelProps={{
                          sx: {
                            color: theme => theme.palette.grey[500]
                          }
                        }}
                      />
                    )}
                  />
                  {errors.contactPhone && (
                    <FormHelperText sx={{ color: 'error.main' }} id='validation-schema-contactPhone'>
                      This field is required
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h6' sx={{ mt: '2rem', mb: '1rem' }}>
                Freshbooks link
              </Typography>
              <Stack direction='row' alignItems='center' gap={4} sx={{ my: 2 }}>
                <Typography>Freshbook ID :</Typography>
                <Button
                  disabled
                  sx={{
                    borderRadius: '0.5rem !important',
                    textTransform: 'capitalize !important',
                    border: theme => `1px solid ${theme.palette.grey[300]}`,
                    color: 'common.black'
                  }}
                >
                  {(freshbooksOrg?.id !== undefined && freshbooksOrg?.id) ||
                    customerInformation?.freshbooksId ||
                    '-----'}
                </Button>
              </Stack>
              <Stack direction='row' alignItems='center' gap={4} sx={{ my: 2 }}>
                <Typography>Freshbooks organization :</Typography>
                {/* Modal for selecting the corresponding Freshbooks Organization */}
                <FreshbooksOrganization
                  createdOrganization={freshbooksOrg}
                  setFreshBooksId={freshbooksIdOnChangeHandler}
                />
              </Stack>
              {customersType == 'google-customers' && (
                <Stack gap={4} sx={{ my: 2 }}>
                  <Typography variant='h7' sx={{ color: 'common.blue' }}>
                    -- OR --
                  </Typography>
                  <Button
                    variant='contained'
                    onClick={handleFreshbooksCreate}
                    // disable={customer.freshbooksId}
                    sx={{
                      width: '30%',
                      borderRadius: '0.5rem !important',
                      textTransform: 'capitalize !important',
                      '&:hover': {
                        backgroundColor: theme => `${theme.palette.primary.main} !important`
                      }
                    }}
                  >
                    Create new
                  </Button>
                </Stack>
              )}
              {freshbooksError && (
                <Typography sx={{ color: 'error.main', mb: '1rem' }}>
                  Please create a freshbooks organization. If you already have one please select it from the suggestions
                  modal.
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} sx={{ textAlign: 'end' }}>
              <Button
                disabled
                size='large'
                variant='contained'
                sx={{ backgroundColor: theme => theme.palette.secondary.light, mx: '0.5rem' }}
              >
                Save
              </Button>
              <Button
                disabled={loading == 'UPDATE_CUSTOMER' || loading == 'CREATE_CUSTOMER'}
                startIcon={
                  (loading == 'UPDATE_CUSTOMER' || loading == 'CREATE_CUSTOMER') && (
                    <CircularProgress sx={{ color: 'common.white' }} size='1rem' />
                  )
                }
                sx={{
                  '&:hover': {
                    backgroundColor: 'common.blue'
                  },
                  '&:disabled': {
                    backgroundColor: theme => theme.palette.secondary.light
                  }
                }}
                size='large'
                type='submit'
                form='customer-information'
                variant='contained'
              >
                {customerInformation.status == 'active' ? 'Update' : 'Finish'}
              </Button>
            </Grid>
          </Container>
        </form>
      )}
      <AlertMessages
        success={success}
        error={error === 'ERROR_CUSTOMER_INFORMATION'}
        messageValue={success === 'UPDATE_INFO' || success === 'CREATE_INFO' ? 'UPDATE' : ''}
        message={success ? 'Customer was updated successfully !' : 'There was an error please try again.'}
      />
    </Fragment>
  )
}

export default CustomerInformations
