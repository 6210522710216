// ** React Imports
import React, { useState } from 'react'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import FormControlLabel from '@mui/material/FormControlLabel'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import { DataGrid } from '@mui/x-data-grid'

// ** Icon Imports
import AlertIcon from 'mdi-material-ui/Alert'
import moment from 'moment'

// Styled Card component
const Plan = styled(Card, { shouldForwardProp: prop => prop !== 'active' })(({ theme, active }) => ({
  boxShadow: '0px 1px 2px 0px rgba(66,66,66,.30), 0px 1px 3px 1px rgba(66,66,66,.15)',
  border: '1px solid #e0e0e0',
  borderRadius: '6px',
  position: 'realtive',
  marginBottom: '16px',
  cursor: 'pointer',
  ...(active ? { border: `2px solid ${theme.palette.primary.main}` } : {})
}))

const flexible_advantages = [
  'No long-term commitment',
  'Pay each month based on users you have each month, starting Sep 18, 2023',
  'Add or remove as many users as needed'
]

const annual_monthly_advantages = [
  'Requires 12 months contract, subject to closeout charge for early termination.',
  'Pay each month based on total licenses committed upfront, starting Oct 18, 2023',
  'Add licenses as needed, remove licenses only when you renew the contract',
  'On completion of your contract period, your subscription will renew as per your renewal settings.'
]
const free_trial_advantages = ['14 days free trial', 'Try services without making a payment']

const returnPrice = (code, offer) => {
  const resource =
    offer?.priceByResources?.find(item => item.price?.basePrice?.currencyCode === code) ??
    offer?.priceByResources?.find(item => item.price?.basePrice?.currencyCode === 'USD')
  return `$ ${(
    parseFloat(resource?.price?.basePrice?.units || 0) +
    parseFloat(resource?.price?.basePrice?.nanos?.toFixed(2) || 0) / 1_000_000_000
  )?.toFixed(2)} ${resource?.price?.basePrice?.currencyCode}`
}

const estimatedPrice = (code, offer, items) => {
  const resource =
    offer?.priceByResources?.find(item => item.price?.basePrice?.currencyCode === code) ??
    offer?.priceByResources?.find(item => item.price?.basePrice?.currencyCode === 'USD')
  if (items > 0 && items < 50) {
    const total =
      (
        parseFloat(resource?.price?.basePrice?.units || 0) +
        parseFloat(resource?.price?.basePrice?.nanos?.toFixed(2) || 0) / 1_000_000_000
      )?.toFixed(2) * items
    return `$ ${total?.toFixed(2)} ${resource?.price?.basePrice?.currencyCode}`
  }
  return `$ ${(
    parseFloat(resource?.price?.basePrice?.units || 0) +
    parseFloat(resource?.price?.basePrice?.nanos?.toFixed(2) || 0) / 1_000_000_000
  )?.toFixed(2)} ${resource?.price?.basePrice?.currencyCode}`
}

const SinglePlan = props => {
  // ** Props
  const { offer, multiple, index, active, setActive, setSelectedOffer } = props

  // ** State
  const [licences, setLicences] = useState(1)
  const [totalPrice, setTotalPrice] = useState(1)

  // ** Constants
  const advantages =
    offer?.plan?.paymentPlan === 'FLEXIBLE'
      ? flexible_advantages
      : offer?.plan?.paymentPlan === 'COMMITMENT'
      ? annual_monthly_advantages
      : free_trial_advantages
  const discount = offer?.priceByResources?.find(item => item.price?.basePrice?.currencyCode === 'CAD')?.price?.discount
    ? true
    : false

  const columns = [
    {
      flex: 0.175,
      field: 'charges',
      headerName:
        offer?.plan?.paymentPlan === 'COMMITMENT' && offer?.plan?.paymentType === 'POSTPAY' ? 'Date' : 'Charges',
      renderCell: params => (
        <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
          {params.row?.resourceType === 'LICENSED_USER' && 'Licensed users (or devices)'}
          {params.row?.resourceType === 'MINUTES' && 'Call usage'}
          {offer?.plan?.paymentPlan === 'COMMITMENT' &&
            offer?.plan?.paymentType === 'POSTPAY' &&
            `Starting ${moment().format('MMM DD, YYYY')}`}
        </Typography>
      )
    },
    {
      flex: 0.25,
      field: 'price',
      headerName: 'Price (actual charges will be in your account currency)',
      renderCell: params => {
        if (offer?.plan?.paymentPlan === 'COMMITMENT' && offer?.plan?.paymentType === 'POSTPAY') {
          return (
            <Box display='flex' alignItems='center' gap={1}>
              <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                ${parseFloat(params.row?.price?.effectivePrice?.units || 0)?.toFixed(2)}
                {params.row?.price?.effectivePrice?.currencyCode} per user/month
              </Typography>
              <Box display='flex' alignItems='center' gap={1}>
                (
                <Typography sx={{ fontSize: 14, fontWeight: 500, textDecoration: 'line-through' }}>
                  ${parseFloat((params.row?.price?.basePrice?.units || 0))?.toFixed(2)}{' '}
                  {params.row?.price?.effectivePrice?.currencyCode}
                </Typography>
                <Typography sx={{ fontSize: 14, fontWeight: 500, color: 'success.main' }}>
                  {(params.row?.price?.discount * 100)?.toFixed(2)}% off
                </Typography>
                )
              </Box>
            </Box>
          )
        }
        return (
          <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
            {params.row?.resourceType === 'LICENSED_USER' ? (
              `$${parseFloat(params.row?.price?.basePrice?.units || 0)?.toFixed(2)} ${
                params.row?.price?.basePrice?.currencyCode
              } per Licensed user (or device)/month`
            ) : params.row?.resourceType === 'MINUTES' ? (
              <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                Fee varies based on country and carrier.{' '}
                <a href={params.row?.price?.externalPriceUri} target='_blank' rel='noopener noreferrer'>
                  View fees
                </a>
              </Typography>
            ) : (
              '----'
            )}
          </Typography>
        )
      }
    }
  ]

  // ** Functions
  const changeTotalPrice = event => setLicences(event.target.value)

  const selectOfferHandler = index => {
    setActive(index)
    setSelectedOffer(offer)
  }

  return (
    <Plan active={multiple ? active === index : false} onClick={() => selectOfferHandler(index)}>
      <Box p='15px 24px 0px 24px'>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {multiple ? (
            <RadioGroup row name='simple-radio' aria-label='simple-radio'>
              <FormControlLabel
                checked={active === index}
                control={<Radio />}
                label={<Typography fontWeight={500}>{offer?.marketingInfo?.displayName}</Typography>}
              />
            </RadioGroup>
          ) : (
            <Typography fontWeight={500}>{offer?.marketingInfo?.displayName}</Typography>
          )}
          {offer?.plan?.paymentPlan === 'TRIAL' ||
          (offer?.plan?.paymentPlan === 'COMMITMENT' && offer?.plan?.paymentType === 'POSTPAY') ? null : (
            <Box textAlign='end'>
              <Typography fontWeight={500}>
                {returnPrice('CAD', offer)} per user/{offer?.plan?.paymentCycle?.periodType?.toLowerCase()}
              </Typography>
              {offer?.plan?.paymentPlan === 'COMMITMENT' ? (
                <Typography variant='subtitle2' fontSize={12} fontWeight={450}>
                  {estimatedPrice('CAD', offer, 1)} per licenses for 12 months
                </Typography>
              ) : null}
            </Box>
          )}
        </Box>
        <Box sx={{ mt: 5, fontSize: 12, color: 'text.secondary' }}>
          <ul>
            {advantages?.map((el, index) => (
              <li key={index} style={{ margin: '6px -20px 6px -20px' }}>
                {el}
              </li>
            ))}
          </ul>
          {discount ? (
            <Typography fontSize={12} mb={3} color='primary.main' p='10px 0px 0px 0px'>
              Add promotion code
            </Typography>
          ) : null}
        </Box>
        {offer?.priceByResources?.some(item => item.resourceType === 'MINUTES') ? (
          <>
            <Typography fontSize={14} fontWeight={450}>
              Each month, you're billed for the total of the charges listed below.
            </Typography>
            <Box>
              <DataGrid
                autoHeight
                headerHeight={41}
                rowHeight={41}
                disableColumnMenu
                disableSelectionOnClick
                hideFooterPagination
                rows={offer?.priceByResources?.map((item, index) => ({ id: index, ...item })) || []}
                columns={columns}
                sx={{
                  mt: 3,
                  '& .MuiDataGrid-row:hover': {
                    backgroundColor: 'inherit'
                  },
                  '& .MuiDataGrid-row:last-child': {
                    '& .MuiDataGrid-cell': {
                      borderBottom: '0px !important'
                    }
                  },
                  '& .MuiDataGrid-columnHeaders': {
                    borderBottom: '1px solid #E0E0E0',
                    '& .MuiDataGrid-columnHeaderTitle': {
                      fontWeight: 500,
                      fontSize: 13,
                      textTransform: 'capitalize'
                    }
                  },
                  '& .MuiDataGrid-main': { border: '1px solid #E0E0E0' }
                }}
              />
            </Box>
          </>
        ) : null}
        {offer?.plan?.paymentPlan === 'COMMITMENT' && offer?.plan?.paymentType === 'POSTPAY' ? (
          <>
            <Typography fontSize={14} fontWeight={450}>
              You are receiving a discount on the standard price.
            </Typography>
            <Box>
              <DataGrid
                autoHeight
                headerHeight={41}
                rowHeight={41}
                disableColumnMenu
                disableSelectionOnClick
                hideFooterPagination
                rows={offer?.priceByResources?.map((item, index) => ({ id: index, ...item })) || []}
                columns={columns}
                sx={{
                  mt: 3,
                  '& .MuiDataGrid-row:hover': {
                    backgroundColor: 'inherit'
                  },
                  '& .MuiDataGrid-row:last-child': {
                    '& .MuiDataGrid-cell': {
                      borderBottom: '0px !important'
                    }
                  },
                  '& .MuiDataGrid-columnHeaders': {
                    borderBottom: '1px solid #E0E0E0',
                    '& .MuiDataGrid-columnHeaderTitle': {
                      fontWeight: 500,
                      fontSize: 13,
                      textTransform: 'capitalize'
                    }
                  },
                  '& .MuiDataGrid-main': { border: '1px solid #E0E0E0' }
                }}
              />
            </Box>
          </>
        ) : null}
      </Box>
      {(!multiple &&
        offer?.priceByResources?.some(item => item.resourceType === 'SEAT') &&
        offer?.plan?.paymentPlan !== 'TRIAL') ||
      (multiple && active === index && offer?.plan?.paymentPlan !== 'TRIAL') ? (
        <Box
          sx={{
            borderTop: theme => `1px solid ${theme.palette.grey[300]}`,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'start',
            p: '15px 24px 15px 24px',
            mb: 2
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography variant='body2' fontSize={13} fontWeight={450}>
              {offer?.plan?.paymentPlan === 'FLEXIBLE' ? 'Total users' : 'Total licenses'}
            </Typography>
            {offer?.plan?.paymentPlan === 'COMMITMENT' ? (
              <>
                <TextField
                  variant='outlined'
                  size='small'
                  type='number'
                  name='licenses'
                  placeholder='Enter license'
                  value={licences}
                  onChange={changeTotalPrice}
                  InputProps={{ inputProps: { min: 1, max: 50 }, style: { borderRadius: '5px', height: 30 } }}
                  sx={{ width: 180 }}
                />
                {licences < 0 || licences > 50 ? (
                  <Typography fontWeight={550} fontSize={12} color='error.main'>
                    {(licences < 0 && 'Must be at least 1') || (licences > 50 && "You can't add more than 50 users")}
                  </Typography>
                ) : null}
              </>
            ) : (
              <Typography fontSize={14}>{licences}</Typography>
            )}
          </Box>
          <Box textAlign='end'>
            <Typography variant='body2' fontSize={13} fontWeight={450}>
              Estimated monthly cost
            </Typography>
            <Typography fontSize={14} fontWeight={450}>
              {estimatedPrice('CAD', offer, licences)}/{offer?.plan?.paymentCycle?.periodType?.toLowerCase()}
            </Typography>
          </Box>
        </Box>
      ) : null}
    </Plan>
  )
}

export default SinglePlan
