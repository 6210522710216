// ** React Imports
import React, { forwardRef, Fragment, useEffect, useState } from 'react'

// ** axios Import
import { getCreateInvoicePastAdvance, postGenerateUsagePastAdvance } from 'configs/axios/api_helper'

// ** MUI Imports
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Typography from '@mui/material/Typography'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Slide from '@mui/material/Slide'
import Grid from '@mui/material/Grid'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import Grow from '@mui/material/Grow'
import Snackbar from '@mui/material/Snackbar'

// ** Third Party imports
import moment from 'moment'

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const GenerateInvoicePastAdvanceModal = props => {
  // ** Props
  const { customers } = props

  // ** State
  const [open, setOpen] = useState(false)
  const [endDate, setEndDate] = useState(new Date().setDate(new Date().getDate() + 1))
  const [startDate, setStartDate] = useState(new Date())
  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState(0)
  const [openSnack, setOpenSnack] = useState(false)

  // ** Fucntions
  const handleClickOpen = () => setOpen(true)
  const handleClose = (event, reason) => {
    if (reason && reason == 'backdropClick' && 'escapeKeyDown') return
    setOpen(false)
  }

  const closeHandler = () => {
    setOpen(false)
  }
  const generateInvoiceHandler = async () => {
    setLoading(true)
    try {
      //setStep(1)
      const start_date = moment(startDate).format('YYYY-MM-DD')
      const end_date = moment(endDate).format('YYYY-MM-DD')
      const body = {
        customers_id: customers.map(String),
        startDate: start_date,
        endDate: end_date
      }
      const params = `?from=${start_date}&to=${end_date}&type=advance&customerId=${customers[0]}`
      await postGenerateUsagePastAdvance(body)
      await getCreateInvoicePastAdvance(params)
      setLoading(false)
      setOpenSnack(true)
      setOpen(false)
    } catch (error) {
      //setStep(0)
      setLoading(false)
    }
  }

  const onChangeStartDateHandler = date => {
    const startDate = new Date(date)
    setStartDate(startDate)

    const year = startDate.getFullYear()
    const month = startDate.getMonth()
    const day = startDate.getDate()

    let endDate

    if (day === 1) {
      // If startDate is the 1st of the month, set endDate to the last day of the same month
      endDate = new Date(year, month + 1, 0) // The 0th day of the next month is the last day of the current month
    } else {
      // Otherwise, set endDate to the same date of the next month minus 1 day
      const nextMonth = new Date(year, month + 1, day)
      nextMonth.setDate(nextMonth.getDate() - 1)
      endDate = nextMonth
    }

    setEndDate(endDate)
  }
  return (
    <Fragment>
      <Button
        disabled={customers.length < 1}
        variant='text'
        onClick={handleClickOpen}
        sx={{
          ml: 'auto',
          boxShadow: 0,
          '&:disabled': {
            color: theme => theme.palette.grey[700]
          }
        }}
      >
        Generate Past Invoice
      </Button>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        TransitionComponent={Transition}
        disableEscapeKeyDown
        aria-labelledby='alert-dialog-slide-title'
        aria-describedby='alert-dialog-slide-description'
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              minWidth: '40%' // Set your width here
            }
          }
        }}
      >
        <DialogTitle id='alert-dialog-slide-title' sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ fontWeight: 600, fontSize: '1.5rem' }}>Generate Past Invoice</Typography>
        </DialogTitle>
        <DialogContent>
          {!loading ? (
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Typography>Select billing range</Typography>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label='Start date'
                    inputFormat='MM/DD/YYYY'
                    value={startDate}
                    onChange={onChangeStartDateHandler}
                    sx={{ width: '100%' }}
                    renderInput={params => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label='End date'
                    inputFormat='MM/DD/YYYY'
                    value={endDate}
                    minDate={new Date(startDate).setDate(new Date().getDate() + 1)}
                    onChange={newValue => setEndDate(newValue)}
                    sx={{ width: '100%' }}
                    renderInput={params => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          ) : null}
          {loading && (
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <CircularProgress size='4rem' />
            </Grid>
          )}
        </DialogContent>
        <DialogActions className='dialog-actions-dense'>
          <Button
            sx={{
              color: 'text.secondary',
              fontWeight: 600,
              '&:hover': {
                backgroundColor: theme => `${theme.palette.common.white} !important`,
                color: 'text.secondary'
              }
            }}
            onClick={closeHandler}
          >
            Cancel
          </Button>
          <Button
            variant='outlined'
            sx={{
              backgroundColor: 'common.blue',
              color: 'common.white',
              fontWeight: 600,
              '&:hover': {
                backgroundColor: theme => `${theme.palette.common.blue} !important`,
                color: 'common.white'
              },
              '&:disabled': {
                backgroundColor: theme => theme.palette.grey[700],
                borderColor: theme => theme.palette.grey[700]
              }
            }}
            onClick={generateInvoiceHandler}
            disabled={loading == 'GENERATE_INVOICE'}
          >
            Generate
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        sx={{ mt: '3rem' }}
        open={openSnack}
        onClose={() => setOpenSnack(false)}
        autoHideDuration={2000}
        key={'top' + 'right'}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        TransitionComponent={Grow}
        message='Invoice was generated successfully!'
      />
    </Fragment>
  )
}

export default GenerateInvoicePastAdvanceModal
