// ** React Imports
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// ** MUI Imports
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'

// ** Custom Components
import PaymentPlan from './PaymentPlan'
import Checkout from './Checkout'
import CustomAvatar from 'core/components/mui/avatar'

// ** Third Party Libraries
import moment from 'moment'
import { products } from '@fake-db/products'

// ** Redux Imports
import { useDispatch, useSelector } from 'react-redux'
import { addNonGoogleSkuSubscription, customersSelector } from 'store/customers'

const steps = ['Payment plan', 'Checkout']

const stepsViews = {
  0: PaymentPlan,
  1: Checkout
}

const HorizontalLinearStepper = props => {
  // ** Props
  const { selectedProduct } = props

  // ** Hooks
  const navigate = useNavigate()
  const dispatch = useDispatch()
  // ** Selectors
  const { customerInformation, loading } = useSelector(customersSelector)

  // ** Constants
  const productIcon = name =>
    products.find(el => el.displayName.toLowerCase() === name?.toLowerCase())?.defaultLogo?.content

  // ** State
  const [startDate, setStartDate] = useState(new Date())
  const [activeStep, setActiveStep] = useState(0)
  const [skipped, setSkipped] = useState(new Set())
  const [selectedPlan, setSelectedPlan] = useState('annual')
  const [licensedNumberOfSeats, setLicensedNumberOfSeats] = useState(1)

  // ** Constants
  const StepView = stepsViews[activeStep]

  // ** Functions
  const isStepOptional = step => {
    return step === 4
  }

  const isStepSkipped = step => {
    return skipped.has(step)
  }

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleRedirect = () => {
    navigate('/')
  }

  const navigationHandler = () => navigate('/customers/customer-display/' + customerInformation?.id)

  const purchaseOfferHandler = () => {
    // Get the current date
    const currentDate = new Date()
    // Add 1 year to the current date
    const oneYearLater = new Date(currentDate)
    oneYearLater.setFullYear(currentDate.getFullYear() + 1)
    // Generate subscriptionId
    const subscriptionId = new Date().getTime().toString()
    // Generate Start Date
    const startTime = new Date().getTime().toString()
    // Get customer first googleId
    const googleId = [...customerInformation?.googleIds]?.sort((a, b) => a.id - b.id)?.[0]?.googleId || null

    const subscriptionData = {
      planName: selectedPlan?.toUpperCase(),
      isCommitmentPlan: selectedPlan === 'annual',
      maximumNumberOfSeats: 100, // fixed to max 100
      numberOfSeats: selectedPlan === 'annual' ? parseInt(licensedNumberOfSeats) : 1,
      licensedNumberOfSeats: selectedPlan === 'flexible' ? parseInt(licensedNumberOfSeats) : 1,
      skuId: selectedProduct?.skuId,
      status: 'ACTIVE',
      skuName: selectedProduct?.skuName,
      customerId: googleId,
      creationTime: startTime,
      endTime: selectedPlan === 'annual' ? oneYearLater.getTime().toString() : null,
      startTime: startTime,
      billingMethod: 'ONLINE',
      isInTrial: false,
      customerDomain: customerInformation?.domain,
      subscriptionId: subscriptionId,
      is_not_google_product: true
    }

    const dailyUsageData = {
      day: moment().utc(false).format('YYYY-MM-DD'), // send time based on the server timezone
      customer_id: customerInformation?.id,
      subscription_id: subscriptionId,
      seats: parseInt(licensedNumberOfSeats),
      sku_name: selectedProduct?.skuName,
      licence_type: selectedPlan === 'annual' ? 'ANNUAL' : 'FLEXIBLE',
      customer_cloud_id: googleId,
      startDate: moment(startDate).format('YYYY-MM-DD'),
      ...(selectedPlan === 'annual'
        ? {
            plan: {
              planName: 'ANNUAL',
              isCommitmentPlan: true,
              commitmentInterval: {
                endTime: oneYearLater.getTime().toString(),
                startTime: startTime
              }
            }
          }
        : {}),
      sku_id: selectedProduct?.skuId,
      customer_domain: customerInformation?.domain,
      discount: []
    }

    const dailyChangesData = {
      subscription_id: subscriptionId,
      day: moment().utc(false).format('YYYY-MM-DD')
    }

    dispatch(addNonGoogleSkuSubscription(subscriptionData, dailyUsageData, dailyChangesData, navigationHandler))
  }

  return (
    <Box>
      <Box sx={{ width: '100%', height: 10 }}>
        <LinearProgress sx={{ ...(loading ? {} : { display: 'none' }) }} />
      </Box>
      <Box
        sx={{
          ...(loading
            ? { opacity: 0.7, pointerEvents: 'none', filter: 'blur(5px)', '-webkit-filter': 'blur(5px)' }
            : {})
        }}
      >
        <Box sx={{ borderBottom: '0.5px solid #e0e0e0', p: '12px 24px 10px 24px' }}>
          <Stepper activeStep={activeStep} sx={{ maxWidth: 430 }}>
            {steps.map((label, index) => {
              const stepProps = {}
              const labelProps = {}
              if (isStepOptional(index)) {
                labelProps.optional = <Typography variant='caption'>Optional</Typography>
              }
              if (isStepSkipped(index)) {
                stepProps.completed = false
              }
              return (
                <Step key={label} {...stepProps} sx={{ whiteSpace: 'nowrap', color: theme => theme.palette.grey[500] }}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              )
            })}
          </Stepper>
        </Box>
        {activeStep === 0 ? (
          <Box
            sx={{
              borderBottom: '0.5px solid #e0e0e0',
              p: '12px 24px 10px 24px',
              display: 'flex',
              gap: 5,
              alignItems: 'center'
            }}
          >
            <CustomAvatar
              sx={{ borderRadius: 0, height: 25, width: 25 }}
              src={productIcon(selectedProduct?.productName)}
            />
            <Box>
              <Typography fontSize={14} fontWeight={500}>
                {selectedProduct?.skuName}
              </Typography>
              <Typography variant='body2' fontSize={12}>
                {selectedProduct?.skuDescription}
              </Typography>
            </Box>
          </Box>
        ) : null}
        {activeStep === steps.length ? (
          <Grid sx={{ maxWidth: '1032px', m: '0 auto', p: '36px 24px 24px 5px' }}>
            <Typography sx={{ mt: 2, mb: 1 }}>
              You have successfully purchase your subscription, you can go back to your subscriptions list for more
              informations.
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleRedirect}>HOME PAGE</Button>
            </Box>
          </Grid>
        ) : (
          <Grid container spacing={2} sx={{ maxWidth: '1032px', m: '0 auto', p: '36px 24px 24px 5px' }}>
            <StepView
              product={selectedProduct}
              setSelectedPlan={setSelectedPlan}
              selectedPlan={selectedPlan}
              setLicensedNumberOfSeats={setLicensedNumberOfSeats}
              setStartDate={setStartDate}
              startDate={startDate}
            />
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                ...(activeStep !== 0 ? { justifyContent: 'space-between' } : { justifyContent: 'end' }),
                pt: 2
              }}
            >
              {activeStep !== 0 ? (
                <Button color='inherit' onClick={handleBack} sx={{ mr: 1 }}>
                  BACK
                </Button>
              ) : null}
              <Button
                // disabled={!selectedPlan && activeStep === 0}
                onClick={activeStep === 1 ? purchaseOfferHandler : handleNext}
                variant='contained'
                width={108}
                sx={{ borderRadius: '4px' }}
              >
                {activeStep === steps.length - 1 ? 'PLACE ORDER' : 'NEXT'}
              </Button>
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  )
}

export default HorizontalLinearStepper
