// ** React Imports
import React from 'react'

// ** Redux Imports
import { useSelector } from 'react-redux'
import { customersSelector } from 'store/customers'
import { currencySelector } from 'store/metaData/currency'

// ** 3rd party libraries
import moment from 'moment'

// ** MUI Imports
import { styled } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import Typography from '@mui/material/Typography'

// ** Custom Components
import TransactionsTableRow from './TransactionTableRow'

// ** Utils
import {
  calculateSingleRow,
  regroupUsages,
  splitArrayBySub,
  splitIntervals,
  formatDiscountDate,
  coverPeriod
} from '../utils'

// Styled Divider component
const StyledTable = styled(Table)(({ theme }) => ({
  marginTop: '1rem',
  borderTop: `0.5px solid ${theme.palette.grey[300]}`,
  '& .MuiTableCell-head': {
    textTransform: 'capitalize',
    fontWeight: 600,
    color: 'text.secondary'
  },
  '& .MuiTableCell-root': {
    fontSize: '0.825rem'
  }
}))

const TransactionsTable = props => {
  // ** Props
  const { type, issueDate, endDate, invoiceDetails, setInAdvanceFormattedTable } = props

  // ** Selectors
  const { customerInformation } = useSelector(customersSelector)
  const { currenciesData } = useSelector(currencySelector)

  // ** Constants
  const startDateObj = moment(issueDate)
  const endDateObj = moment(endDate)

  let taxedAmount = 0
  let transactionAmount = 0
  let subtotalAmount = 0
  const currencyId =
    customerInformation?.currency?.id || currenciesData?.find(item => item.iso_code === 'CAD')?.id || null

  // ** Variables
  let rows = splitIntervals(invoiceDetails)?.filter(item => !!item.seats) || []

  // ** Functions

  // ** Initialization reformatted array for in advance case
  if (type === 'advance') {
    // Calculate the difference in months between start_date and end_date
    const diffInMonths = endDateObj.diff(startDateObj, 'months')

    // Split usage data by subscriptions
    const subsUsages = splitArrayBySub([...invoiceDetails])
    let formattedSubUsages = []

    // Check if the difference is greater than 1 month
    if (diffInMonths > 1) {
      // Proceed with regrouping the usage data
      for (const subUsage of subsUsages) {
        const inAdvanceUsage = subUsage?.filter(item => new Date(item.day) >= new Date(issueDate))
        const rearUsage = subUsage?.filter(item => new Date(item.day) < new Date(issueDate))
        const [singleDailyUsage] = inAdvanceUsage
        let matchedSpanUsage = null

        rearUsage?.reverse()?.forEach(item => {
          let shouldBreak = false
          if (!shouldBreak) {
            const lastDay = [...item.days]?.pop()
            if (matchedSpanUsage?.seats === item?.seats) {
              if (moment(lastDay).isSame(moment(matchedSpanUsage?.day).subtract(1, 'day'))) {
                matchedSpanUsage = item
              }
            } else if (
              moment(lastDay).isSame(moment(singleDailyUsage?.day).subtract(1, 'day')) &&
              item.seats === singleDailyUsage?.seats
            ) {
              matchedSpanUsage = item
            } else {
              shouldBreak = true
            }
          }
        })

        formattedSubUsages = [
          ...formattedSubUsages,
          regroupUsages(subUsage, matchedSpanUsage?.day || issueDate, currencyId)
        ]
      }

      // Set the result to your in-advance table
      setInAdvanceFormattedTable(formattedSubUsages?.flat() || [])
      rows = formattedSubUsages?.flat()
    } else {
      // If the difference is 1 month or less, no regrouping is needed
      // Still calculate total and totalSeats to ensure consistency
      formattedSubUsages = subsUsages.map(subUsage =>
        subUsage.map(item => {
          const total = item.prices?.find(p => p.currencyId === currencyId)?.totalValue || 0
          return { ...item, total, totalSeats: item.seats }
        })
      )

      // Set the result to your in-advance table without regrouping
      setInAdvanceFormattedTable(formattedSubUsages?.flat() || [])
      rows = formattedSubUsages?.flat()
    }
  } else {
    // If not 'advance' type, set the table to an empty array or handle accordingly
    setInAdvanceFormattedTable([])
  }

  rows = rows?.map(item => {
    // Get domain for each item
    const domain =
      customerInformation?.googleIds?.find(el =>
        el.subscriptions?.some(sub => sub.subscriptionId === item.subscription_id)
      )?.domain ?? customerInformation?.domain

    // Format the discount information for each item
    const discount = formatDiscountDate(
      customerInformation,
      item.discount?.[0],
      item.subscription_id,
      item.licence_type,
      item.discount,
      issueDate,
      endDate
    )

    // Return modified item with domain and description
    return {
      ...item,
      domain: domain,
      description: `Domain: ${domain}\n ${coverPeriod(item, item.intervals, item.type, type) || ''}${
        item.discount?.length > 0 ? ` \n${discount}` : ''
      }`
    }
  })

  if (rows) {
    // Precompute domain and SKU total prices
    const domainSummedPrices = rows.reduce((acc, item) => {
      const itemDomain =
        customerInformation?.googleIds?.find(el =>
          el.subscriptions?.some(sub => sub.subscriptionId === item.subscription_id)
        )?.domain ?? customerInformation?.domain

      const price = item.prices?.find(el => el.currencyId === currencyId)?.totalValue ?? 0
      //const skuCost = calculateSingleRow(item.prices, item.total, currencyId, type)*
      //  ((type === 'advance' ? item.totalSeats : item.quantity) || 1)

      acc[itemDomain] = (acc[itemDomain] || 0) + price
      return acc
    }, {})

    const skuSummedPrices = rows.reduce((acc, item) => {
      const price = item.prices?.find(el => el.currencyId === currencyId)?.totalValue ?? 0
      //const skuCost = calculateSingleRow(item.prices, item.total, currencyId, type)*
      //((type === 'advance' ? item.totalSeats : item.quantity) || 1)

      acc[item.sku_id] = (acc[item.sku_id] || 0) + price
      return acc
    }, {})

    // Sort rows
    rows = rows.sort((a, b) => {
      // Primary: Total domain cost
      const domainCostA = domainSummedPrices[a.domain] || 0
      const domainCostB = domainSummedPrices[b.domain] || 0
      if (domainCostA !== domainCostB) return domainCostB - domainCostA

      // Secondary: Group by domain
      if (a.domain !== b.domain) return a.domain > b.domain ? 1 : -1

      // Tertiary: Total SKU cost
      const skuCostA = skuSummedPrices[a.sku_id] || 0
      const skuCostB = skuSummedPrices[b.sku_id] || 0
      if (skuCostA !== skuCostB) return skuCostB - skuCostA

      // Quaternary: Latest date
      const latestDateA = Math.max(...(a.days || []).map(date => new Date(date).getTime()), 0)
      const latestDateB = Math.max(...(b.days || []).map(date => new Date(date).getTime()), 0)
      if (latestDateA !== latestDateB && type === 'advance') return latestDateB - latestDateA
      if (latestDateA !== latestDateB && type === 'rear') return latestDateA - latestDateB

      // Fallback: Price
      const priceA = skuSummedPrices[a.sku_id] || 0
      const priceB = skuSummedPrices[b.sku_id] || 0
      return priceB - priceA
    })
  }

  // Calculate the price from taxes fees
  const taxedPrice = () => {
    const totalTax = customerInformation?.taxes?.reduce((acc, { tax: { value } }) => acc + parseFloat(value), 0) || 0
    taxedAmount =
      invoiceDetails?.reduce(
        (acc, { prices }) => acc + (prices?.find(item => item?.currencyId === currencyId)?.totalValue || 0),
        0
      ) *
      (parseFloat(totalTax) / 100)
    return `$${taxedAmount.toFixed(2)}`
  }

  // Calculate total taxes percent
  const calculateTotalTaxesPercent = () => {
    const taxesName = customerInformation?.taxes?.map(el => el?.tax?.name)?.join(' & ')
    const sumValues = customerInformation?.taxes
      ?.reduce((acc, { tax: { value } }) => acc + parseFloat(value), 0)
      ?.toFixed(0)
    return taxesName + ' (' + sumValues + '%)'
  }

  // Calculate the price from payment method fees
  const paymentMethodFees = () => {
    // ** Don't apply tax for monthly cons logic..
    // invoiceDetails?.reduce(
    //   (acc, element) =>
    //     acc +
    //     (!element.resource_type
    //       ? element.prices?.find(item => item?.currencyId === currencyId)?.totalValue || 0
    //       : 0) *
    //       (element.quantity || 1),
    //   0
    // )
    taxedPrice()
    transactionAmount =
      (invoiceDetails?.reduce(
        (acc, { prices }) => acc + (prices?.find(item => item?.currencyId === currencyId)?.totalValue || 0),
        0
      ) +
        parseFloat(taxedAmount)) *
      (parseFloat(customerInformation?.paymentMethod?.value) / 100)

    return `$${transactionAmount.toFixed(2)}`
  }

  // Calculte rows balance
  const calculateRowsBalance = () => {
    subtotalAmount = invoiceDetails
      ?.reduce((acc, { prices }) => acc + (prices?.find(item => item?.currencyId === currencyId)?.totalValue || 0), 0)
      ?.toFixed(2)
    return `$${subtotalAmount}`
  }

  // Calculate ending balance
  const calculateEndingBalance = () => {
    const total = (parseFloat(subtotalAmount) + parseFloat(transactionAmount) + parseFloat(taxedAmount)).toFixed(2)
    if (customerInformation?.currency?.iso_code === 'EUR') {
      return `€ ${total}`
    }
    return `$${total}`
  }

  return (
    <TableContainer component={Paper}>
      <StyledTable>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Description</TableCell>
            <TableCell align='right'>Quantity</TableCell>
            <TableCell align='right'>Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map(row => (
            <TransactionsTableRow key={`${row.day}-${row.subscription_id}`} row={row} type={type} />
          ))}
          <TableRow>
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell align='right' sx={{ whiteSpace: 'nowrap' }}>
              <Typography sx={{ color: 'common.black', fontSize: '0.875rem', my: 2 }}>
                Subtotal ({customerInformation?.currency?.iso_code ?? 'CAD'})&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;
                {calculateRowsBalance()}
              </Typography>
              {customerInformation?.taxes?.length > 0 && (
                <Typography sx={{ color: 'common.black', fontSize: '0.875rem', my: 2 }}>
                  {calculateTotalTaxesPercent()}
                  &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;{taxedPrice()}
                </Typography>
              )}
              {customerInformation?.paymentMethod?.value > 0 && (
                <Typography sx={{ color: 'common.black', fontSize: '0.875rem', my: 2 }}>
                  Transactions fees ({customerInformation?.paymentMethod?.value}%)&nbsp; &nbsp; &nbsp;&nbsp; &nbsp;
                  &nbsp;{paymentMethodFees()}
                </Typography>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell align='right'>
              <Typography>Total &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;{calculateEndingBalance()}</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </StyledTable>
    </TableContainer>
  )
}

export default React.memo(TransactionsTable)
