// ** React Imports
import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'

// ** Third Part Libraries
import { useForm, Controller } from 'react-hook-form'
import { useGoogleLogin } from '@react-oauth/google'
import moment from 'moment'

// ** MUI Components
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import { styled } from '@mui/material/styles'
import MuiCard from '@mui/material/Card'
import Alert from '@mui/material/Alert'
import Grid from '@mui/material/Grid'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import TextField from '@mui/material/TextField'
import Snackbar from '@mui/material/Snackbar'
import CircularProgress from '@mui/material/CircularProgress'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import DoneAllIcon from 'mdi-material-ui/CheckAll'

// ** Custome Component
import FallbackSpinner from 'core/components/spinner'

// ** APIs
import { getOrderForm, postOrderForm } from 'configs/axios/api_helper'

// ** Styled Components
const Card = styled(MuiCard)(({ theme }) => ({
  zIndex: 1,
  display: 'block',
  boxShadow: 'none',
  border: `solid 1px ${theme.palette.grey[300]}`,
  height: 'auto',
  padding: '2.5rem'
}))

const GoogleButton = styled(Button)(({ theme }) => ({
  fontSize: '0.92rem',
  textTransform: 'none',
  //width: "fit-content",
  paddingLeft: '1.5rem',
  paddingRight: '1.5rem',
  fontWeight: 600,
  color: theme.palette.common.black,
  borderRadius: '0.3em',
  height: 48,
  width: 240,
  marginBottom: '1.725rem',
  textAlign: 'center',
  border: `1px solid ${theme.palette.grey[300]}`,
  '&:hover': {
    backgroundColor: theme.palette.grey[200],
    border: `1px solid ${theme.palette.grey[300]}`
  }
}))

const Forms = () => {
  // ** Hooks
  const { id } = useParams()
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      customer_domain: '',
      full_customer_address: '',
      customer_phone: '',
      customer_signer_name: '',
      customer_signer_title: '',
      customer_signer_email: '',
      signature_date: null,
      contact_name: '',
      contact_title: '',
      contact_phone: '',
      contact_email: '',
      ap_contact_name: '',
      ap_contact_phone: '',
      ap_contact_email: ''
    },
    mode: 'onChange'
  })
  // ** States
  const [isLoading, setIsLoading] = useState(false)
  const [formLoading, setFormLoading] = useState(false)
  const [error, setError] = useState(false)
  const [user, setUser] = useState(null)
  const [isValid, setIsValid] = useState(false)
  const [isDone, setIsDone] = useState(false)
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')

  // ** Functions
  const googleLogin = useGoogleLogin({
    onSuccess: async ({ access_token }) => {
      // fetching userinfo can be done on the client or the server from google api
      try {
        setIsLoading(true)
        const userInfo = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: { Authorization: `Bearer ${access_token}` }
        }).then(res => res.json())
        setUser(userInfo)
        const { data } = await getOrderForm(id)
        setIsValid(data?.customer_signer_email && data?.customer_signer_email === userInfo?.email)
        setIsLoading(false)
        reset({
          customer_domain: data.customer_domain,
          full_customer_address: data.full_customer_address,
          customer_phone: data.customer_phone,
          customer_signer_name: data.customer_signer_name,
          customer_signer_title: data.customer_signer_title,
          customer_signer_email: data.customer_signer_email,
          signature_date: new Date(data.signature_date),
          contact_name: data.contact_name,
          contact_title: data.contact_title,
          contact_phone: data.contact_phone,
          contact_email: data.contact_email,
          ap_contact_name: data.ap_contact_name,
          ap_contact_phone: data.ap_contact_phone,
          ap_contact_email: data.ap_contact_email
        })
        setIsDone(data?.status === 'done')
        console.log('ssss: ', userInfo, data)
      } catch (error) {
        setError(error)
      }
    }
  })

  const handlerClose = () => {
    setOpen(false)
  }
  const onSubmit = async (data, e) => {
    try {
      setFormLoading(true)
      const body = {
        ...data,
        signature_date: moment(data.signature_date).format('YYYY-MM-DD'),
        status: 'done'
      }
      await postOrderForm(id, body)
      setFormLoading(false)
      setMessage('Form order has been sent successfully !')
      setOpen(true)
    } catch (error) {
      if (error?.response?.status === 401) setMessage('This Order is already sent.')
      else setMessage('An error has occurred please try again.')
      setOpen(true)
      setFormLoading(false)
    }
  }

  if (isLoading)
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          mt: '20%'
        }}
      >
        <FallbackSpinner />
      </Box>
    )

  if (isDone && isValid)
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 5,
          minHeight: '100vh'
        }}
      >
        <Typography variant='h5' sx={{ fontWeight: 600 }}>
          This form is already submitted.
        </Typography>
        <DoneAllIcon sx={{ fontSize: '2rem', color: 'success.main' }} />
      </Box>
    )

  if (user && isValid)
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box sx={{ height: 'fit-content', bgcolor: 'white', px: 20, py: 5 }}>
          <Typography variant='h4' sx={{ my: '2rem', fontWeight: 600 }}>
            Order Form
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <Typography variant='h6' sx={{ mb: '1rem' }}>
                  Customer Domain
                </Typography>
                <FormControl fullWidth>
                  <Controller
                    name='customer_domain'
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        value={value ?? ''}
                        label='Domain Name'
                        onChange={onChange}
                        error={Boolean(errors.customer_domain)}
                        aria-describedby='validation-basic-customer_domain'
                        InputLabelProps={{
                          sx: {
                            color: theme => theme.palette.grey[500]
                          }
                        }}
                      />
                    )}
                  />
                  {errors.customer_domain && (
                    <FormHelperText sx={{ color: 'error.main' }} id='validation-basic-customer_domain'>
                      {errors.customer_domain.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6' sx={{ mb: '1rem' }}>
                  Customer Full Address
                </Typography>
                <FormControl fullWidth>
                  <Controller
                    name='full_customer_address'
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        value={value ?? ''}
                        label='Full Address'
                        onChange={onChange}
                        error={Boolean(errors.full_customer_address)}
                        aria-describedby='validation-basic-full_customer_address'
                        InputLabelProps={{
                          sx: {
                            color: theme => theme.palette.grey[500]
                          }
                        }}
                      />
                    )}
                  />
                  {errors.full_customer_address && (
                    <FormHelperText sx={{ color: 'error.main' }} id='validation-basic-full_customer_address'>
                      {errors.full_customer_address.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6' sx={{ mb: '1rem' }}>
                  Customer Phone
                </Typography>
                <FormControl fullWidth>
                  <Controller
                    name='customer_phone'
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        value={value ?? ''}
                        label='Phone'
                        onChange={onChange}
                        error={Boolean(errors.customer_phone)}
                        aria-describedby='validation-basic-customer_phone'
                        InputLabelProps={{
                          sx: {
                            color: theme => theme.palette.grey[500]
                          }
                        }}
                      />
                    )}
                  />
                  {errors.customer_phone && (
                    <FormHelperText sx={{ color: 'error.main' }} id='validation-basic-customer_phone'>
                      {errors.customer_phone.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6' sx={{ mb: '1rem' }}>
                  Customer Name
                </Typography>
                <FormControl fullWidth>
                  <Controller
                    name='customer_signer_name'
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        value={value ?? ''}
                        label='Name'
                        onChange={onChange}
                        error={Boolean(errors.customer_signer_name)}
                        aria-describedby='validation-basic-customer_signer_name'
                        InputLabelProps={{
                          sx: {
                            color: theme => theme.palette.grey[500]
                          }
                        }}
                      />
                    )}
                  />
                  {errors.customer_signer_name && (
                    <FormHelperText sx={{ color: 'error.main' }} id='validation-basic-customer_signer_name'>
                      {errors.customer_signer_name.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6' sx={{ mb: '1rem' }}>
                  Customer Signer Title
                </Typography>
                <FormControl fullWidth>
                  <Controller
                    name='customer_signer_title'
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        value={value ?? ''}
                        label='Title'
                        onChange={onChange}
                        error={Boolean(errors.customer_signer_title)}
                        aria-describedby='validation-basic-customer_signer_title'
                        InputLabelProps={{
                          sx: {
                            color: theme => theme.palette.grey[500]
                          }
                        }}
                      />
                    )}
                  />
                  {errors.customer_signer_title && (
                    <FormHelperText sx={{ color: 'error.main' }} id='validation-basic-customer_signer_title'>
                      {errors.customer_signer_title.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6' sx={{ mb: '1rem' }}>
                  Customer Signer Email
                </Typography>
                <FormControl fullWidth>
                  <Controller
                    name='customer_signer_email'
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        value={value ?? ''}
                        label='Email'
                        onChange={onChange}
                        error={Boolean(errors.customer_signer_email)}
                        aria-describedby='validation-basic-customer_signer_email'
                        InputLabelProps={{
                          sx: {
                            color: theme => theme.palette.grey[500]
                          }
                        }}
                      />
                    )}
                  />
                  {errors.customer_signer_email && (
                    <FormHelperText sx={{ color: 'error.main' }} id='validation-basic-customer_signer_email'>
                      {errors.customer_signer_email.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6' sx={{ mb: '1rem' }}>
                  Signature Date
                </Typography>
                <FormControl fullWidth>
                  <Controller
                    name='signature_date'
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <DesktopDatePicker
                        label='Start billing date'
                        value={value ?? null}
                        inputFormat='MM/DD/YYYY'
                        onChange={onChange}
                        sx={{ width: '100%' }}
                        slotProps={{ textField: { size: 'small' } }}
                        renderInput={params => <TextField {...params} fullWidth />}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6' sx={{ mb: '1rem' }}>
                  Contact name
                </Typography>
                <FormControl fullWidth>
                  <Controller
                    name='contact_name'
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        value={value ?? ''}
                        label='Contact name'
                        onChange={onChange}
                        error={Boolean(errors.contact_name)}
                        aria-describedby='validation-basic-contact_name'
                        InputLabelProps={{
                          sx: {
                            color: theme => theme.palette.grey[500]
                          }
                        }}
                      />
                    )}
                  />
                  {errors.contact_name && (
                    <FormHelperText sx={{ color: 'error.main' }} id='validation-basic-contact_name'>
                      {errors.contact_name.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6' sx={{ mb: '1rem' }}>
                  Contact title
                </Typography>
                <FormControl fullWidth>
                  <Controller
                    name='contact_title'
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        value={value ?? ''}
                        label='Contact title'
                        onChange={onChange}
                        error={Boolean(errors.contact_title)}
                        aria-describedby='validation-basic-contact_title'
                        InputLabelProps={{
                          sx: {
                            color: theme => theme.palette.grey[500]
                          }
                        }}
                      />
                    )}
                  />
                  {errors.contact_title && (
                    <FormHelperText sx={{ color: 'error.main' }} id='validation-basic-contact_title'>
                      {errors.contact_title.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6' sx={{ mb: '1rem' }}>
                  Contact phone
                </Typography>
                <FormControl fullWidth>
                  <Controller
                    name='contact_phone'
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        value={value ?? ''}
                        label='Contact phone'
                        onChange={onChange}
                        error={Boolean(errors.contact_phone)}
                        aria-describedby='validation-basic-contact_phone'
                        InputLabelProps={{
                          sx: {
                            color: theme => theme.palette.grey[500]
                          }
                        }}
                      />
                    )}
                  />
                  {errors.contact_phone && (
                    <FormHelperText sx={{ color: 'error.main' }} id='validation-basic-contact_phone'>
                      {errors.contact_phone.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6' sx={{ mb: '1rem' }}>
                  Contact phone
                </Typography>
                <FormControl fullWidth>
                  <Controller
                    name='contact_email'
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        value={value ?? ''}
                        label='Contact email'
                        onChange={onChange}
                        error={Boolean(errors.contact_email)}
                        aria-describedby='validation-basic-contact_email'
                        InputLabelProps={{
                          sx: {
                            color: theme => theme.palette.grey[500]
                          }
                        }}
                      />
                    )}
                  />
                  {errors.contact_email && (
                    <FormHelperText sx={{ color: 'error.main' }} id='validation-basic-contact_email'>
                      {errors.contact_email.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6' sx={{ mb: '1rem' }}>
                  AP contact name
                </Typography>
                <FormControl fullWidth>
                  <Controller
                    name='ap_contact_name'
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        value={value ?? ''}
                        label='AP contact name'
                        onChange={onChange}
                        error={Boolean(errors.ap_contact_name)}
                        aria-describedby='validation-basic-ap_contact_name'
                        InputLabelProps={{
                          sx: {
                            color: theme => theme.palette.grey[500]
                          }
                        }}
                      />
                    )}
                  />
                  {errors.ap_contact_name && (
                    <FormHelperText sx={{ color: 'error.main' }} id='validation-basic-ap_contact_name'>
                      {errors.ap_contact_name.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6' sx={{ mb: '1rem' }}>
                  AP contact phone
                </Typography>
                <FormControl fullWidth>
                  <Controller
                    name='ap_contact_phone'
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        value={value ?? ''}
                        label='AP contact phone'
                        onChange={onChange}
                        error={Boolean(errors.ap_contact_phone)}
                        aria-describedby='validation-basic-ap_contact_phone'
                        InputLabelProps={{
                          sx: {
                            color: theme => theme.palette.grey[500]
                          }
                        }}
                      />
                    )}
                  />
                  {errors.ap_contact_phone && (
                    <FormHelperText sx={{ color: 'error.main' }} id='validation-basic-ap_contact_phone'>
                      {errors.ap_contact_phone.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6' sx={{ mb: '1rem' }}>
                  AP contact email
                </Typography>
                <FormControl fullWidth>
                  <Controller
                    name='ap_contact_email'
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        value={value ?? ''}
                        label='AP contact email'
                        onChange={onChange}
                        error={Boolean(errors.ap_contact_email)}
                        aria-describedby='validation-basic-ap_contact_email'
                        InputLabelProps={{
                          sx: {
                            color: theme => theme.palette.grey[500]
                          }
                        }}
                      />
                    )}
                  />
                  {errors.ap_contact_email && (
                    <FormHelperText sx={{ color: 'error.main' }} id='validation-basic-ap_contact_email'>
                      {errors.ap_contact_email.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sx={{ textAlign: 'end' }}>
                <Button
                  startIcon={formLoading ? <CircularProgress sx={{ color: 'common.white' }} size='1rem' /> : null}
                  size='large'
                  type='submit'
                  variant='contained'
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
          <Snackbar
            open={open}
            onClose={handlerClose}
            message={message}
            autoHideDuration={2000}
            key={'top' + 'right'}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          />
        </Box>
      </LocalizationProvider>
    )

  return (
    <Box className='content-center' sx={{ minHeight: '100vh' }}>
      <Card>
        <CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <img width={180} height={85} alt='google_cloud' src='/premier_cloud.png' />
            <Typography
              variant='h6'
              sx={{ ml: 2, mt: '3rem', lineHeight: 1, fontWeight: 700, fontSize: '1.5rem !important' }}
            >
              Sign In
            </Typography>
          </Box>
          <Box sx={{ mb: 6, textAlign: 'center', mt: '0.5rem' }}>
            <Typography variant='body1'>Sign in with your Google account</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <GoogleButton
              onClick={googleLogin}
              variant='outlined'
              startIcon={<img width={20} height={20} alt='google-logo' src='/logo/google-logo.svg' />}
            >
              Sign in with Google
            </GoogleButton>
          </Box>
          {user && !isValid && <Alert severity='error'>You are not authorized !</Alert>}
        </CardContent>
      </Card>
    </Box>
  )
}

export default Forms
